import React, { useState, useEffect } from 'react';
import Helmet from 'react-helmet';
import { useHistory } from 'react-router-dom';

const shareImage = require('./share-image.jpg');
const logoImage = require('./logo.png');

const HelmetDefaults = () => {
  const history = useHistory();

  const getCanonicalUrl = () => {
    const { hostname, pathname } = window.location;
    return `http${process.env.NODE_ENV === 'development' ? '' : 's'}://${hostname}${pathname}`;
  };

  const [ canonicalUrl, setCanonicalUrl ] = useState(getCanonicalUrl());

  useEffect(() => {
    const unlisten = history.listen(() => {
      setCanonicalUrl(getCanonicalUrl());
    });

    return unlisten;
  }, []);

  return (
    <Helmet>
      <meta property="og:site_name" content="KidPass Tutors By Begin" />
      <meta property="og:type" content="website" />
      <meta property="og:image" content={shareImage} />
      <meta property="og:image:width" content="1340" />
      <meta property="og:image:height" content="754" />
      <meta name="twitter:card" content="summary_large_image" />
      <meta name="twitter:image" content={shareImage} />
      <link rel="canonical" href={canonicalUrl} />
      <script type="application/ld+json">
        {`{
          "@context": "http://schema.org/",
          "@type": "Organization",
          "@id": "https://kidpasstutors.com/#organization",
          "name": "KidPass Tutors By Begin",
          "telephone": "+8888410487",
          "logo": "https://kidpasstutors.com${logoImage}",
          "url": "https://kidpasstutors.com"
        }`}
      </script>
    </Helmet>
  );
};

export default HelmetDefaults;
