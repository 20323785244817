import React, { useEffect } from 'react';

import { MeetOurTeam, Metrics, MissionAndProduct } from './sections';
import {
  ContactUs,
  FeaturedIn,
  Hero,
} from '../../components/sections';
import { useStoreContext } from '../../StoreContext';
import { useHelmet } from '../../utils';

const Team = () => {
  const { dispatch } = useStoreContext();
  const helmet = useHelmet({
    title: 'About KidPass Tutors',
    description: 'Our team of impact-driven leaders and passionate learning experts are on a mission to help all students unlock their potential.',
    breadcrumbs: {'Team': '/team'},
  });

  useEffect(() => {
    dispatch({ type: 'set-current-page', payload: 'team' });
  }, []);

  return (
    <div>
      {helmet}
      <Hero.Team />
      <FeaturedIn />
      <MissionAndProduct />
      <Metrics />
      <MeetOurTeam />
      <ContactUs locationId="Team" />
    </div>
  );
};

export default Team;
