import PropTypes from 'prop-types';
import React from 'react';
import { Link } from 'react-router-dom';

const Anchor = (props) => {
  const { to, children, ...rest } = props;

  if (to?.match(/(^https?:\/\/|www\.|\.com$)/i)) {
    return <a href={to} target="_BLANK" rel="noreferrer" {...rest}>{children}</a>;
  }

  return <Link to={to} {...rest}>{children}</Link>;
};

Anchor.propTypes = {
  to: PropTypes.string.isRequired,
  children: PropTypes.oneOf([PropTypes.node, PropTypes.string]).isRequired,
  rest: PropTypes.object,
};

export default Anchor;
