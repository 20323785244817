const breakpoints = {
  XSMALL_DOWN: 'only screen and (max-width: 359px)',
  XSMALL_UP: 'only screen and (min-width: 360px)',
  SMALL_DOWN: 'only screen and (max-width: 539px)',
  SMALL_UP: 'only screen and (min-width: 540px)',
  MEDIUM_DOWN: 'only screen and (max-width: 767px)',
  MEDIUM_UP: 'only screen and (min-width: 768px)',
  TABLET_ONLY: 'only screen and (min-width: 768px) and (max-width: 992px)',
  LARGE_DOWN: 'only screen and (max-width: 991px)',
  LARGE_UP: 'only screen and (min-width: 992px)',

  // speciality (aiming towards semantic > DRY)
  NAVIGATION_MOBILE: 'only screen and (max-width: 1119px)',
  NAVIGATION_DESKTOP: 'only screen and (min-width: 1120px)',
};

const baseSpace = 5;

const theme = {
  // colors
  palette: {
    primary: {
      main: '#00B4F0',
      dark: '#009CCF',
      light: '#E8F9FF',
    },
    secondary: {
      main: '#ed6169',
      dark: '#bb4a51',
    },
    tertiary: {
      main: '#0F4163',
    },
    gray: {
      main: '#ADADAD',
      light: '#E7E7E7',
      lightest: '#F9F9F9',
      dark: '#9B9B9B',
      darkest: '#666467',
      border: '#D7D7D7',
    },
    alert: {
      main: '#EC2323',
    },
    success: {
      main: '#23A055',
    },
    text: {
      main: '#333333',
    },
    yellow: {
      lightest: '#fdf8f1',
    },
  },

  // responsive
  breakpoints,
  sectionMaxWidth: 1340,

  // general
  layers: {
    BOTTOM: 1,
    1: 1000,
    2: 2000,
    3: 3000,
    4: 4000,
    5: 5000,
    6: 6000,
    7: 7000,
    8: 8000,
    9: 9000,
    TOP: 9999,
  },

  fontWeight: {
    normal: 300,
    medium: 500,
    bold: 700,
  },

  baseSpace,

  // utilities
  spacing: (w = 1, x, y, z) => {
    const handleVal = val => {
      if (typeof val === 'string') {
        return val;
      }

      return `${baseSpace * val}px`;
    };

    if (typeof x === 'undefined') {
      return handleVal(w);
    }

    if (typeof y === 'undefined') {
      return `${handleVal(w)} ${handleVal(x)}`;
    }

    return `${handleVal(w)} ${handleVal(x)} ${handleVal(y)} ${handleVal(z)}`;
  },
  radius: (val = 1) => `${4 * val}px`,
  mediaRule: breakpoint => `@media ${breakpoints[breakpoint]}`,
};

export default theme;
