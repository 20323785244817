import React from 'react';
import { createUseStyles, useTheme } from 'react-jss';
import { useMediaQuery } from '@react-hook/media-query';

const abcLogo = require('./assets/abc-logo.svg');
const wsjLogo = require('./assets/wsj-logo.svg');
const cbsLogo = require('./assets/cbs-logo.svg');
const mnLogo = require('./assets/mn-logo.svg');
const fortuneLogo = require('./assets/fortune-logo.svg');
const forbesLogo = require('./assets/forbes-logo.svg');
const featuredMobileLogos = require('./assets/featured-mobile-logos.svg');

const useStyles = createUseStyles(theme => ({
  featuredIn: {
    width: '100%',
    background: theme.palette.gray.lightest,
  },
  inner: {
    maxWidth: `${theme.sectionMaxWidth}px`,
    padding: theme.spacing(4, 4),
    margin: '0 auto',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    fontSize: '18px',
    lineHeight: 1.33,
    color: theme.palette.gray.dark,
    fontWeight: theme.fontWeight.medium,
    [theme.mediaRule('MEDIUM_UP')]: {
      flexDirection: 'row',
      justifyContent: 'center',
    },
    [theme.mediaRule('LARGE_UP')]: {
      paddingLeft: '50px',
      paddingRight: '50px',
    },
  },
  logosWrapper: {
    flex: 1,
    width: '100%',
    margin: theme.spacing(2, 0, 0, 0),
    '& > img': {
      width: '100%',
    },
    [theme.mediaRule('MEDIUM_UP')]: {
      width: 'auto',
      maxWidth: '810px',
      display: 'flex',
      flexDirection: 'row',
      alignItems: 'center',
      justifyContent: 'space-between',
      margin: theme.spacing(0, 0, 0, 7),
      gap: theme.spacing(6),
      '& > img': {
        width: 'auto',
        minWidth: 0,
      },
    },
  },
}));

const FeaturedIn = () => {
  const classes = useStyles();
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.MEDIUM_DOWN);

  return (
    <section id="featuredInSection" className={classes.featuredIn}>
      <div className={classes.inner}>
        As Featured In:
        <div className={classes.logosWrapper}>
          { isMobile ?
            <img id="mobileFeaturedInLogos" alt="Featured In Logos" src={featuredMobileLogos} />
            : (
              <>
                <img id="featuredInForbesLogo" alt="Forbes Logo" src={forbesLogo} style={{ maxHeight: '21.5px' }} />
                <img id="featuredInWSJLogo" alt="The Wall Street Journal Logo" src={wsjLogo} style={{ maxHeight: '18px' }} />
                <img id="featuredInABCLogo" alt="ABC Logo" src={abcLogo} style={{ maxHeight: '29.7px' }} />
                <img id="featuredInCBSLogo" alt="CBS Logo" src={cbsLogo} style={{ maxHeight: '20.4px' }} />
                <img id="featuredInFortuneLogo" alt="Fortune Logo" src={fortuneLogo} style={{ maxHeight: '21.6px' }} />
                <img id="featuredInMommyNearestLogo" alt="Mommy Nearest Logo" src={mnLogo} style={{ maxHeight: '20px' }} />
              </>
            )}
        </div>
      </div>
    </section>
  );
};

export default FeaturedIn;
