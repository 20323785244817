import PropTypes from 'prop-types';
import React from 'react';
import { createUseStyles, useTheme } from 'react-jss';
import { useMediaQuery } from '@react-hook/media-query';

import BaseHero from './BaseHero';

const heroImage = require('./assets/kpt-template-hero.webp');
const heroMobileImage = require('./assets/kpt-template-hero-mobile.webp');

const useStyles = createUseStyles(theme => ({
  templateHero: {
    [theme.mediaRule('MEDIUM_DOWN')]: {
      height: '400px',
    },
    [theme.mediaRule('MEDIUM_UP')]: {
      backgroundPosition: 'center',
    },
    '& [class^=inner]': {
      [theme.mediaRule('MEDIUM_DOWN')]: {
        alignItems: 'flex-end',
        paddingBottom: theme.spacing(6),
      },
    },
    '& h1, & h3': {
      textShadow: '0 4px 4px rgba(0, 0, 0, .1)',
    },
    '& h1': {
      maxWidth: 'none',
    },
    '& h3': {
      display: 'none',
      [theme.mediaRule('MEDIUM_UP')]: {
        display: 'block',
        maxWidth: '750px',
      },
    },
  },
}));

const TemplateHero = (props) => {
  const { title, ...rest } = props;
  const classes = useStyles();
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.MEDIUM_DOWN);

  return (
    <BaseHero
      className={classes.templateHero}
      image={isMobile ? heroMobileImage : heroImage}
      title={title}
      subTitle="KidPass Tutors has helped thousands of learners achieve their goal - reach out to match with the perfect tutor!"
      locationId="Template"
      {...rest}
    />
  );
};

TemplateHero.propTypes = {
  title: PropTypes.string.isRequired,
  rest: PropTypes.object,
};

export default TemplateHero;
