import PropTypes from 'prop-types';
import React from 'react';
import { createUseStyles, useTheme } from 'react-jss';
import { useMediaQuery } from '@react-hook/media-query';

const useStyles = createUseStyles({
  stack: {
    display: 'flex',
    position: 'relative',
  },
});

const Stack = props => {
  const {
    direction = 'row',
    alignItems = 'flex-start',
    justifyContent = 'flex-start',
    spacing = 0,
    breakpoint,
    children,
    ...rest
  } = props;
  const theme = useTheme();
  const classes = useStyles();
  const isNotStacked = useMediaQuery(theme.breakpoints[breakpoint]);

  return isNotStacked ? children : (
    <div
      className={classes.stack}
      style={{
        flexDirection: direction,
        alignItems,
        justifyContent,
        gap: theme.spacing(spacing),
      }}
      {...rest}
    >
      {children}
    </div>
  );
};

Stack.propTypes = {
  direction: PropTypes.oneOf(['row', 'row-reverse', 'column', 'column-reverse']),
  alignItems: PropTypes.oneOf(['flex-start', 'center', 'flex-end', 'stretch', 'baseline']),
  justifyContent: PropTypes.oneOf(['flex-start', 'center', 'flex-end', 'space-between', 'space-around', 'space-evenly']),
  spacing: PropTypes.number,
  breakpoint: PropTypes.string,
  children: PropTypes.node,
  rest: PropTypes.object,
};

export default Stack;
