import React from 'react';
import Helmet from 'react-helmet';
import { useLocation } from 'react-router-dom';

const logoImage = require('../logo.png');

function useHelmet(options) {
  const location = useLocation();
  const pathname = location.pathname !== '/' ? location.pathname : '';

  const defaultTitle = 'KidPass Tutors: Top-Rated Tutoring for Young Learners';

  return (
    <Helmet title={options?.title}>
      { options?.description && <meta name="description" content={options.description} /> }
      { options?.robots && <meta name="robots" content={options.robots} /> }
      <script type="application/ld+json">
        {`{
          "@context": "http://schema.org/",
          "@type": "Service",
          "@id": "https://kidpasstutors.com${pathname}/#service",
          "serviceType": "Tutoring Service",
          "audience": {
            "@type": "EducationalAudience",
            "educationalRole": "Student"
          },
          "url": "https://kidpasstutors.com${pathname}",
          "name": "${options?.title || defaultTitle}",
          "description": "${options?.description || ''}",
          "logo": "https://kidpasstutors.com${logoImage}",
          "provider": {
            "@id": "https://kidpasstutors.com/#organization"
          }
        }`}
      </script>
      <script type="application/ld+json">
        {`{
          "@context": "http://schema.org/",
          "@type": "BreadcrumbList",
          "itemListElement": [
            ${
              [{Home: '/'}].concat(options?.breadcrumbs).map((breadcrumb, index) => {
                if (typeof breadcrumb !== 'object') {
                  return;
                }

                const key = Object.keys(breadcrumb)[0];
                return `
                  {
                    "@type": "ListItem",
                    "name": "${key}",
                    "position": ${index + 1},
                    "item": "https://kidpasstutors.com${breadcrumb[key]}"
                  }
                `;
              }).filter(Boolean).join(',')
            }
          ]
        }`}
      </script>
    </Helmet>
  );
}

export default useHelmet;
