const { datadogLogs } = require('@datadog/browser-logs');

const { mapEnvToDD, getVersion } = require('./envMappingTools');

let singletonLogger = null;
const configDataDogRum = (serviceName) => {
  if (singletonLogger === null) {
    const configuration = {
      forwardErrorsToLogs: true,
      sampleRate: 100,
      forwardConsoleLogs: [
        'error',
      ],
      applicationId: '803a016a-1238-43f9-b3d5-acc1d7741005',
      clientToken: 'pub661d176745a2c73a912a41c602d09095',
      service: serviceName,
      env: mapEnvToDD(process.env.NODE_ENV),
      version: getVersion(),
    };
    singletonLogger = datadogLogs.init(configuration);

    // eslint-disable-next-line no-console
    console.log(`DataDog client logger started in environment [${configuration.env}] for version [${configuration.version}]`);
  }
  return singletonLogger;
};

module.exports = configDataDogRum;
