import React from 'react';
import { createUseStyles, useTheme } from 'react-jss';
import { useMediaQuery } from '@react-hook/media-query';

import { useStoreContext } from '../../../StoreContext';
import Button from '../../../components/Button';

const productImage = require('../assets/product.webp');

const useStyles = createUseStyles(theme => ({
  section: {
    width: '100%',
  },
  inner: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    textAlign: 'center',
    margin: '0 auto',
    padding: theme.spacing('40px', 4, '100px', 4),
    maxWidth: `${theme.sectionMaxWidth}px`,
    [theme.mediaRule('MEDIUM_UP')]: {
      padding: theme.spacing('60px', 4, '180px', 4),
    },
    [theme.mediaRule('LARGE_UP')]: {
      paddingLeft: '50px',
      paddingRight: '50px',
    },
    '& button': {
      marginBottom: '100px',
      [theme.mediaRule('MEDIUM_UP')]: {
        width: '250px',
        marginBottom: '180px',
      },
    },
    '& h1': {
      fontSize: '28px',
      lineHeight: 1.29,
      fontWeight: theme.fontWeight.medium,
      marginBottom: theme.spacing(2),
      [theme.mediaRule('MEDIUM_UP')]: {
        fontSize: '32px',
        lineHeight: 1.25,
        marginBottom: theme.spacing(3),
      },
    },
  },
  message: {
    fontSize: '20px',
    lineHeight: 1.4,
    marginBottom: theme.spacing(4),
    maxWidth: '900px',
    [theme.mediaRule('MEDIUM_UP')]: {
      fontSize: '24px',
      lineHeight: 1.33,
      marginBottom: theme.spacing(6),
    },
  },
  textImageWrapper: {
    display: 'flex',
    alignItems: 'center',
    width: '100%',
    flexDirection: 'column',
    [theme.mediaRule('LARGE_UP')]: {
      flexDirection: 'row',
      gap: theme.spacing(4),
      justifyContent: 'space-between',
    },
    '& h1': {
      marginBottom: theme.spacing(3),
    },
  },
  productImage: {
    width: '100%',
    minHeight: '150px',
    backgroundImage: `url(${productImage})`,
    backgroundRepeat: 'no-repeat',
    backgroundSize: 'contain',
    backgroundPosition: 'center center',
    marginBottom: theme.spacing(5),
    [theme.mediaRule('LARGE_UP')]: {
      height: '305px',
      marginBottom: 0,
    },
  },
  textCol: {
    fontSize: '20px',
    lineHeight: 1.4,
    [theme.mediaRule('LARGE_UP')]: {
      textAlign: 'left',
      maxWidth: '620px',
      minWidth: '450px',
      fontSize: '24px',
      lineHeight: 1.33,
    },
  },
  imageCol: {
    display: 'flex',
    width: '100%',
    flexDirection: 'column',
    alignItems: 'center',
    [theme.mediaRule('LARGE_UP')]: {
      flex: 1,
      maxWidth: '520px',
      minWidth: '400px',
      alignItems: 'flex-start',
    },
  },
}));

const MissionAndProduct = () => {
  const classes = useStyles();
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.LARGE_DOWN);
  const { handleOpenCloseModal } = useStoreContext();

  return (
    <section className={classes.section}>
      <div className={classes.inner}>
        <h1>We Want the Best for Young Learners</h1>
        <div className={classes.message}>
          <p style={{marginBottom: '1.65em'}}>
            KidPass Tutors has helped millions of students achieve their learning goals, whether that is catching up in a subject, staying ahead of the curve, or boosting study skills.
            We are driven by our mission to connect parents and students with high-quality tutors to solve the problem of providing personalized, flexible tutoring for kids ages 3-13+ in all subjects.
            KidPass Tutors is a seamless, trusted solution that connects, engages, and achieves results.
          </p>

          <p>
            Our impact is in large part owing to certified, caring educators on our platform who genuinely enjoy helping students learn and thrive.
            They challenge students to reach their potential, help them build confidence, and inspire a lifelong love for learning.
            We believe in empowering tutors to make a meaningful difference while growing as educators.
          </p>
        </div>
        <Button id="buttonTeamMission" variant="contained" fullWidth={isMobile} onClick={handleOpenCloseModal}>Get Started</Button>

        <div className={classes.textImageWrapper}>
          <div className={classes.imageCol}>
            { isMobile && <h1>We Offer the Best Platform and Tutors</h1> }
            <div className={classes.productImage} />
          </div>
          <div className={classes.textCol}>
            { !isMobile && <h1>We Offer the Best Platform and Tutors</h1> }
            Our solution is everything a parent and their learner would need. We offer online and in-person personalized 1:1 tutoring across all subjects, grade levels, and locations. Tutors on our platform are certified teachers with years of experience and deep subject knowledge. Our innovative platform unlocks convenient scheduling, seamless communication, and engaging sessions. All the while, we provide continuous feedback for parents to see their learner&apos;s progress.
          </div>
        </div>
      </div>
    </section>
  );
};

export default MissionAndProduct;
