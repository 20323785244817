import PropTypes from 'prop-types';
import React from 'react';

import { CustomRadio, ErrorMessage } from '../../../form';
import Step from './BaseStep';
import Stack from '../../../layout/Stack';

const UrgencyStep = (props) => {
  return (
    <Step>
      <h4 id="questionSoon">How soon does your child need help?</h4>
      <ErrorMessage
        align="center"
        errorMessages={props.errorMessages}
      />
      <Stack spacing={2} direction="column" onChange={props.handleOnChange}>
        <CustomRadio id="answerSoonASAP" label="Right away" value="ASAP" name="urgency" />
        <CustomRadio id="answerSoonWeeks" label="In a few weeks" value="in a few weeks" name="urgency" />
        <CustomRadio id="answerSoonNotSure" label="Not sure" value="Not sure" name="urgency"/>
      </Stack>
    </Step>
  );
};

UrgencyStep.propTypes = {
  handleOnChange: PropTypes.func,
  errorMessages: PropTypes.array,
};

export default UrgencyStep;
