import React, { useEffect, useState } from 'react';
import { useParams, useHistory, useLocation } from 'react-router-dom';
import isEmpty from 'lodash/isEmpty';
import capitalize from 'lodash/capitalize';

import { Tutors } from './sections';
import {
  About,
  ContactUs,
  FeaturedIn,
  Hero,
} from '../../components/sections';
import { useStoreContext } from '../../StoreContext';
import Loader from '../../components/Loader';
import classesJSON from './data/classes.json';
import subjectsJSON from './data/subjects.json';
import { useHelmet } from '../../utils';

const validClasses = classesJSON.data;
const validClassesAndSubjects = [
  ...validClasses,
  ...subjectsJSON.data,
];

const Template = () => {
  const history = useHistory();
  const params = useParams();
  const [cities, setCities] = useState();
  const [city, setCity] = useState();
  const [data, setData] = useState();
  const { dispatch } = useStoreContext();
  const { pathname } = useLocation();

  const getCities = async () => {
    setCities({});
    const cityData = await import('./data/cities.json');
    setCities(cityData);
  };

  const reset = () => {
    setCity();
    setData();
  };

  useEffect(() => {
    dispatch({ type: 'set-current-page', payload: 'template' });
    return history.listen(reset);
  }, []);

  useEffect(() => {
    (async () => {
      const { path, filter1, filter2 } = params;

      if (validClassesAndSubjects.includes(filter1)) {
        if (filter2 || (path === 'classes' && !validClasses.includes(filter1))) {
          history.replace('/');
          return;
        }

        const formatAndSubjectData = await import(`./data/${filter1}.json`);
        setData(formatAndSubjectData);
        return;
      }

      if (typeof cities !== 'object') {
        getCities();
        return;
      }

      if (!isEmpty(cities)) {
        if (typeof cities[filter1] === 'undefined') {
          history.replace('/');
          return;
        }

        setCity(cities[filter1]);

        if (filter2) {
          if (!validClassesAndSubjects.includes(filter2) || (path === 'classes' && !validClasses.includes(filter2))) {
            history.replace('/');
            return;
          }

          const formatAndSubjectData = await import(`./data/${filter2}.json`);
          setData(formatAndSubjectData);
        }
      }
    })();
  }, [params, cities]);

  let title;
  let aboutMessage;
  let description;
  let loading = false;
  const pathTerm = params.path === 'classes' ? 'classes' : 'tutors';

  if (city && data) {
    title = `${data.title} ${capitalize(pathTerm)} Near You in ${city}`;
    aboutMessage = data.message;
    description = `Choose from the best ${data.title} ${capitalize(pathTerm)} near you in ${city}. Personalized, private tutoring that guarantees results.`;
  } else if (city) {
    title = `Tutors in ${city}`;
    aboutMessage = `
      KidPass Tutors is an award winning program that offers customized, one-to-one instruction with certified teachers.
      We offer convenient scheduling and support every step of the way and guarantee results.
      Get started today with a free educational consultation to determine your child’s needs and get matched with the perfect tutor in ${city}.
    `;
    description = `Choose from the best ${pathTerm} near you in ${city}. Personalized, private tutoring available near you and online.`;
  } else if (data) {
    title = `${data.title} ${capitalize(pathTerm)}`;
    aboutMessage = data.message;
    description = `Choose from the best ${data.title} ${pathTerm}. Personalized, private tutoring available near you and online.`;
  } else {
    loading = true;
  }

  const helmet = useHelmet({
    title: `Top ${title} | KidPass Tutors`,
    description,
    breadcrumbs: {[title]: pathname},
  });

  if (loading) {
    return <Loader />;
  }

  return (
    <div>
      {helmet}
      <Hero.Template title={title} />
      <FeaturedIn />
      <About
        title={`Why Choose KidPass Tutors${data ? ` for ${data.header || data.title}` : ''}`}
        message={aboutMessage}
        locationId="Template"
      />
      <Tutors />
      <ContactUs locationId="Template" style={{background: 'white'}} />
    </div>
  );
};

export default Template;
