const footerItems = {
  'Company': {
    'About': '/team',
    'KidPass Classes': 'https://kidpass.com',
    'Terms of Service': 'https://kidpass.com/terms',
    'Privacy Policy': 'https://kidpass.com/privacy',
    'Contact Us': 'https://homerlearning-kidpass.kustomer.help/contact/contact-us-B11fIiTrK',
  },
  'Top Subjects': {
    'French': '/tutoring/french',
    'German': '/tutoring/german',
    'Mandarin Chinese': '/tutoring/mandarin-chinese',
    'Math': '/tutoring/math',
    'Phonics': '/tutoring/phonics',
    'Reading': '/tutoring/reading',
    'School Readiness': '/tutoring/school-readiness',
    'Science': '/tutoring/science',
    'Social Emotional Learning': '/tutoring/social-emotional-learning',
    'Social Studies': '/tutoring/social-studies',
    'Spanish': '/tutoring/spanish',
    'Writing': '/tutoring/writing',
  },
  'Top Grade Levels': {
    'Preschool': '/tutoring/preschool',
    'Pre-K': '/tutoring/pre-k',
    'Kindergarten': '/tutoring/kindergarten',
    '1st Grade': '/tutoring/first-grade',
    '2nd Grade': '/tutoring/second-grade',
    '3rd Grade': '/tutoring/third-grade',
    '4th Grade': '/tutoring/fourth-grade',
    '5th Grade': '/tutoring/fifth-grade',
    '6th Grade': '/tutoring/sixth-grade',
    '7th Grade': '/tutoring/seventh-grade',
    '8th Grade': '/tutoring/eighth-grade',
    'High School': '/tutoring/high-school',
  },
  'Top Locations': {
    'Boston': '/tutoring/boston-ma',
    'Charlotte': '/tutoring/charlotte-nc',
    'Chicago': '/tutoring/chicago-il',
    'Columbus': '/tutoring/columbus-oh',
    'Dallas Fort Worth': '/tutoring/dallas-tx',
    'Denver': '/tutoring/denver-co',
    'Houston': '/tutoring/houston-tx',
    'Indianapolis': '/tutoring/indianapolis-in',
    'Las Vegas': '/tutoring/las-vegas-nv',
    'Los Angeles': '/tutoring/los-angeles-ca',
    'Miami': '/tutoring/miami-fl',
    'Milwaukee': '/tutoring/milwaukee-wi',
    'Nashville': '/tutoring/nashville-tn',
    'New York City': '/tutoring/new-york-ny',
    'Oklahoma City': '/tutoring/oklahoma-city-ok',
    'Orlando': '/tutoring/orlando-fl',
    'Phoenix': '/tutoring/phoenix-az',
    'Raleigh-Durham': '/tutoring/raleigh-durham-nc',
    'Sacramento': '/tutoring/sacramento-ca',
    'San Antonio': '/tutoring/san-antonio-tx',
    'San Diego': '/tutoring/san-diego-ca',
    'San Fancisco / Bay Area': '/tutoring/san-francisco-ca',
    'San Jose': 'san-jose-ca',
    'Seattle': '/tutoring/seattle-wa',
    'Washington D.C.': '/tutoring/washington-dc-dc',
  },
};

export default footerItems;
