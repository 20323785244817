import React from 'react';
import { createUseStyles } from 'react-jss';
import Helmet from 'react-helmet';

const trustPilotImage = require('./assets/trustpilot-logo.svg');

const useStyles = createUseStyles(theme => ({
  trustPilot: {
    width: '100%',
  },
  inner: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'center',
    padding: theme.spacing(5, 4),
    gap: theme.spacing(3),
  },
  logo: {
    width: '88px',
    height: '22px',
    imageRendering: '-webkit-optimize-contrast',
    backgroundImage: `url(${trustPilotImage})`,
    backgroundRepeat: 'no-repeat',
    backgroundSize: 'contain',
    backgroundPosition: 'center center',
  },
  widgetWrapper: {
    /* magic numbers to make it look right */
    width: '210px',
    height: '15px',
    position: 'relative',
    '& .trustpilot-widget': {
      width: '310px',
      position: 'absolute !important',
      left: '-105px',
    },
    '& iframe': {
      width: '100%',
    },
  },
}));

const TrustPilot = () => {
  const classes = useStyles();

  return (
    <>
      <Helmet>
        <script type="text/javascript" src="//widget.trustpilot.com/bootstrap/v5/tp.widget.bootstrap.min.js" async></script>
      </Helmet>
      <section id="trustPilotSection" className={classes.trustPilot}>
        <div className={classes.inner}>
          <div className={classes.logo} role="img" aria-label="TrustPilot Logo" />

          <div className={classes.widgetWrapper}>
            <div
              className="trustpilot-widget"
              data-locale="en-US"
              data-template-id="577258fb31f02306e4e3aaf9"
              data-businessunit-id="62e1593858241b8e384d7323"
              data-style-height="20px"
              data-theme="light"
              data-sku="KPT-1"
              data-star-color="#00B67A"
              data-no-reviews="hide"
              data-scroll-to-list="true"
              data-style-alignment="right"
            >
              <a href="https://www.trustpilot.com/review/kidpasstutors.com" target="_blank" rel="noopener noreferrer">Trustpilot</a>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default TrustPilot;
