import PropTypes from 'prop-types';
import React, { createContext, useContext, useReducer, useCallback } from 'react';

const StoreContext = createContext();

function useStore() {
  const initialState = {
    showModal: false,
    currentPage: '',
    headerHeight: 0,
  };
  const [state, dispatch] = useReducer((prevState, action) => {
    switch(action?.type) {
    case 'toggle-modal':
      return { ...prevState, showModal: !prevState.showModal };
    case 'set-current-page':
      return { ...prevState, currentPage: action?.payload };
    case 'set-header-height':
      return { ...prevState, headerHeight: +action?.payload || 0 };
    default:
      return prevState;
    }
  }, initialState);

  const handleOpenCloseModal = useCallback(() => {
    dispatch({ type: 'toggle-modal' });
  }, []);

  const setHeaderHeight = useCallback((val) => {
    dispatch({ type: 'set-header-height', payload: val });
  }, []);

  return {
    state,
    dispatch,
    handleOpenCloseModal,
    setHeaderHeight,
  };
}

function useStoreContext() {
  return useContext(StoreContext);
}

const StoreProvider = ({children}) => {
  const store = useStore();

  return (
    <StoreContext.Provider value={store}>
      {children}
    </StoreContext.Provider>
  );
};

StoreProvider.propTypes = {
  children: PropTypes.node,
};

export {
  StoreProvider,
  StoreContext,
  useStore,
  useStoreContext,
};
