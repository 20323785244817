import PropTypes from 'prop-types';
import React from 'react';
import { createUseStyles, useTheme } from 'react-jss';
import { useMediaQuery } from '@react-hook/media-query';

import Button from '../../Button';
import Subject, { maxWidth as subjectMaxWidth } from './Subject';
import { useStoreContext } from '../../../StoreContext';

const languagesIcon = require('./assets/languages.svg');
const mathIcon = require('./assets/math.svg');
const phonicsIcon = require('./assets/phonics.svg');
const readingIcon = require('./assets/reading.svg');
const schoolReadinessIcon = require('./assets/school-readiness.svg');
const writingIcon = require('./assets/writing.svg');

const useStyles = createUseStyles(theme => ({
  subjects: {
    width: '100%',
    '& button': {
      maxWidth: subjectMaxWidth,
      [theme.mediaRule('MEDIUM_UP')]: {
        width: '250px',
      },
    },
  },
  inner: {
    textAlign: 'center',
    padding: theme.spacing('80px', 4),
    maxWidth: `${theme.sectionMaxWidth}px`,
    margin: '0 auto',
    [theme.mediaRule('MEDIUM_UP')]: {
      padding: theme.spacing('120px', '50px'),
    },
    '& > h1': {
      fontSize: '28px',
      lineHeight: 1.29,
      fontWeight: theme.fontWeight.medium,
      marginBottom: theme.spacing(4),
      [theme.mediaRule('MEDIUM_UP')]: {
        fontSize: '32px',
        lineHeight: 1.25,
        marginBottom: theme.spacing(8),
      },
    },
  },
  subjectsWrapper: {
    display: 'grid',
    gridTemplateColumns: 'repeat(2, 1fr)',
    gap: theme.spacing(2),
    rowGap: theme.spacing(6),
    marginBottom: theme.spacing(4),
    [theme.mediaRule('MEDIUM_UP')]: {
      gridTemplateColumns: 'repeat(3, 1fr)',
      gap: theme.spacing(7),
      rowGap: theme.spacing(8),
      marginBottom: theme.spacing(8),
    },
  },
}));

const Subjects = (props) => {
  const {
    locationId = '',
  } = props;
  const classes = useStyles();
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.LARGE_DOWN);
  const { handleOpenCloseModal } = useStoreContext();

  return (
    <section id="subjectsSection" className={classes.subjects}>
      <div className={classes.inner}>
        <h1 id="subjectsTitle">Experts in Early Learning Subjects</h1>
        <div id="subjectsWrappper" className={classes.subjectsWrapper}>
          <Subject
            id="schoolReadinessSubject"
            icon={schoolReadinessIcon}
            iconLabel="A stack of shapes"
            subject="School Readiness"
            description="Foster academic confidence and build important social-emotional skills"
          />
          <Subject
            id="phonicsSubject"
            icon={phonicsIcon}
            iconLabel="A group of letter blocks"
            subject="Phonics"
            description="Decode the puzzle of sounds, letters, and words"
          />
          <Subject
            id="readingSubject"
            icon={readingIcon}
            iconLabel="A stack of books"
            subject="Reading"
            description="Sharpen comprehension and fluency skills to read above grade level"
          />
          <Subject
            id="mathSubject"
            icon={mathIcon}
            iconLabel="A group of numbers"
            subject="Math"
            description="Lay down building blocks for loving math and feeling confident about numbers"
          />
          <Subject
            id="languagesSubject"
            icon={languagesIcon}
            iconLabel="Speech bubbles"
            subject="Languages"
            description="Develop lifelong language and communication skills"
          />
          <Subject
            id="writingSubject"
            icon={writingIcon}
            iconLabel="A pencil and paper"
            subject="Writing"
            description="Build writing skills and develop a passion for creative storytelling"
          />
        </div>
        <Button id={`buttonSubjects${locationId}`} variant="contained" fullWidth={isMobile} onClick={handleOpenCloseModal}>Get Started</Button>
      </div>
    </section>
  );
};

Subjects.propTypes = {
  locationId: PropTypes.string,
};

export default Subjects;
