import React, { useState } from 'react';
import Modal from 'react-modal';
import { createUseStyles } from 'react-jss';
import cx from 'classnames';
import { useHistory, useLocation } from 'react-router-dom';
import Cookies from 'js-cookie';
import { without } from 'lodash';

import { useStoreContext } from '../../../StoreContext';
import Step from './Steps/BaseStep';
import {
  FullName,
  ZipCode,
  Email,
  PhoneNumber,
  validateEmail,
  validatePhone,
  validateZip,
} from './Steps/MultipleSteps';
import TutoringTypeStep from './Steps/TutoringTypeStep';
import ChildAge from './Steps/ChildAge';
import UrgencyStep from './Steps/UrgencyStep';
import {
  logger,
  pushToDataLayer,
  useQuery,
} from '../../../utils';

const useStyles = createUseStyles((theme)=>({
  modalContainer: {
    height: '100%',
    width: '100%',
    backgroundColor: '#fff',
    fontSize: '10px',
  },
  questionsContainer: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'flex-start',
    maxWidth: '69rem',
    margin: '0 auto',
    padding: '2rem 2rem 0 2rem',
    marginTop: '7.3rem',
    [theme.mediaRule('MEDIUM_UP')]: {
      paddingTop: '3rem',
    },
  },
  stepCount: {
    marginTop: 0,
    marginBottom: 0,
    color: theme.palette.gray.dark,
    lineHeight: '1.6em',
    fontSize: '1.2em',
  },
  greyContainer: {
    color: '#000',
    backgroundColor: '#eeeeee',
    width: '100%',
    [theme.mediaRule('MEDIUM_UP')]: {
      width: '25em',
    },
  },
  roundedContainer: {
    borderRadius: '4px',
    margin: '.8em 0 2em 0',
  },
  progressContainer: {
    padding: '0.01rem 1.6rem',
    color: '#fff',
    backgroundColor: theme.palette.primary.main,
    height: '.8rem',
  },
  buttonContainer: {
    display: 'flex',
    alignItems: 'center',
    flexDirection: 'column',
    position: 'relative',
    marginLeft: 'auto',
    marginRight: 'auto',
    [theme.mediaRule('MEDIUM_UP')]: {
      flexDirection: 'row',
      justifyContent: 'flex-start',
      alignItems: 'baseline',
      textAlign: 'left',
      width: '127rem',
    },
  },
  consentMessage: {
    fontSize: '1.4rem',
    lineHeight: 1.29,
    marginBottom: theme.spacing(2),
    '& a': {
      color: theme.palette.primary.main,
      textDecoration: 'none',
      '&:hover, &:focus-visible, &:active': {
        textDecoration: 'underline',
      },
    },
  },
  controlButtons: {
    height: '3.8rem',
    width: '25rem',
  },
  skipButton: {
    display: 'inline-block',
    fontSize: '1.4rem',
    lineHeight: '1.8rem',
    color: theme.palette.gray.dark,
    padding: '1.5rem',
    textAlign: 'center',
    cursor: 'pointer',
    fontWeight: theme.fontWeight.medium,
    '&:hover':{
      color: '#7d7d7d',
    },
    [theme.mediaRule('MEDIUM_UP')]:{
      display: 'flex',
      alignItems: 'center',
      textAlign: 'left',
    }},
  button: {
    letterSpacing: '.025rem',
    marginTop: 0,
    backgroundColor: theme.palette.primary.main,
    color: '#fff',
    textTransform: 'uppercase',
    fontSize: '1.4rem',
    padding: '0.85rem 1.3rem',
    fontWeight: theme.fontWeight.bold,
    lineHeight: '1.8rem',
    display:'inline-block',
    marginBottom: 0,
    textAlign: 'center',
    cursor: 'pointer',
    userSelect: 'none',
    border: '1px solid transparent',
    borderRadius: '4px',
    '&:hover':{
      backgroundColor: theme.palette.primary.dark,
    },
    [theme.mediaRule('MEDIUM_DOWN')]: {
      width: '100%',
    },
  },
  header: {
    fontSize: '2.2rem',
    fontWeight: theme.fontWeight.medium,
    textTransform: 'none',
    marginTop: '1rem',
    marginBottom: '1rem',
    lineHeight: 1.1,
    [theme.mediaRule('MEDIUM_UP')]: {
      fontSize: '2.4rem',
    },
  },
  '@global': {
    'body.ReactModal__Body--open':{
      '& header': {
        '& [class^="navWrapper"], #buttonPhoneTopDesktop, #buttonFormTopDesktop, & [class^="hamburgerIcon"], & [id^="buttonPhoneTopMobile"], .bannerOptimizeExperience':{
          display: 'none',
        },
      },
    },
  },
}));

const GetStartedForm = () => {
  const { state: { showModal, currentPage }, handleOpenCloseModal } = useStoreContext();
  const { pathname } = useLocation();

  const classes = useStyles();
  const defaultData = {
    firstName: '',
    lastName: '',
    zipCode: '',
    phone: '',
    email: '',
    description: '',
    childAge: '',
    frequency: '',
    format: '',
    tutoringType: [],
    tutoringTypeOther: '',
    urgency: '',
    packageSize: '',
    plan: '',
  };

  const steps = [
    {
      id: 'questionChildAge',
      nextId: 'buttonChildAgeNext',
      skipId: 'linkChildAgeSkip',
    },
    {
      id: 'questionType',
      nextId: 'buttonTypeNext',
      skipId: 'linkTypeSkip',
    },
    {
      id: 'questionSoon',
      nextId: 'buttonSoonNext',
      skipId: 'linkSoonSkip',
    },
    {
      id: 'questionName',
      nextId: 'buttonNameNext',
      skipId: '',
    },
    {
      id: 'questionZip',
      nextId: 'buttonZipNext',
      skipId: '',
    },
    {
      id: 'questionEmail',
      nextId: 'buttonEmailNext',
      skipId: '',
    },
    {
      id: 'questionPhone',
      nextId: 'buttonPhoneSubmit',
      skipId: '',
    },
  ];
  const [ currentStep, setStep ] = useState(0);
  const [ formValues, setFormValues ] = useState(defaultData);
  const baseConfig = {
    removeSteps: [],
  };

  let actualConfig = { removeSteps: [] };

  if (window && window.tutoringRequest) {
    actualConfig = { ...baseConfig, ...window.tutoringRequest };
  }

  const res = steps.filter((item) => !actualConfig.removeSteps.includes(item.id));

  const totalSteps = res.length;
  const isLastStep = currentStep === totalSteps - 1;

  const [ optionSelected, setOptionSelected ] = useState('');

  const messages = [];
  const [ errorMessages, setErrorMessages ] = useState([]);

  const handleOnChange = (event) => {
    const {
      name,
      value,
      type,
      checked,
    } = event.target;

    setOptionSelected(value);
    setErrorMessages([]);
    if (type === 'checkbox') {
      let newValue;

      if (checked) {
        newValue = [ ...formValues[name], value ];
      } else {
        newValue = without(formValues[name], value);
      }

      setFormValues({
        ...formValues,
        [name]: newValue,
      });
    }
    else {
      let { tutoringType } = formValues;
      if (name === 'tutoringTypeOther') {
        document.querySelector('#answerTypeOther input[type="checkbox"]').checked = !!value;
        if (value.length) {
          if (!tutoringType.includes('other')) {
            tutoringType.push('other');
          }
        } else {
          tutoringType = without(tutoringType, 'other');
        }
      }

      setFormValues({
        ...formValues,
        tutoringType,
        [name]: value,
      });
    }
  };
  const skipQuestion = () => {
    if (errorMessages.length !== 0) setErrorMessages([]);
    if (currentStep < steps.length - 1) {
      setOptionSelected('');
      setStep(currentStep + 1);
    }
  };

  const stepProps = {
    formValues, steps, handleOnChange, errorMessages,
  };

  const getPercentage = (current, total) => {
    return Math.floor((current * 100) / total);
  };

  const header = <h4 className={classes.header}>Great! Just a few more details so that we can find the best tutor for you.</h4>;

  const urlParams = useQuery();
  const stepTest = res[currentStep];
  const history = useHistory();

  const utmMedium = urlParams.get('utm_medium') || '';
  const utmSource = urlParams.get('utm_source') || '';
  const utmCampaign = urlParams.get('utm_campaign') || '';
  const utmContent = urlParams.get('utm_content') || '';
  const campaignid = urlParams.get('campaignid') || '';
  const keyword = urlParams.get('keyword') || '';
  const adgroupid = urlParams.get('adgroupid') || '';
  const matchtype = urlParams.get('matchtype') || '';
  const creative = urlParams.get('creative') || '';
  const device = urlParams.get('device') || '';
  const source = window.location.href || '';

  const isProd = ['prod', 'production'].includes(process.env.NODE_ENV);
  const salesforceOID = isProd ? '00D36000001ECta' : '00DE10000000bQz';

  const postLeadToSFDC = () => {
    const {
      firstName, lastName, email, phone, zipCode, urgency, tutoringType, tutoringTypeOther, childAge,
    } = formValues;
    const form = {
      lead_source: 'Inbound',
      oid: salesforceOID,
      retURL: 'http://kidpasstutors.com/thank-you',
      recordType: '0121R000000P5JZ',
      first_name: firstName,
      last_name: lastName,
      email,
      phone,
      zip: zipCode,
      '00NEX000000PAuF': childAge,
      '00N1R00000Tu9g0': urgency,
      '00N1R00000TX4Bw': tutoringType.join(', '),
      '00N1R00000TX4C1': tutoringTypeOther,
      company: 'KP Tutors',
      '00N1R00000TuA4R': utmMedium,
      '00N1R00000TuA4M': utmSource,
      '00N1R00000TuA4W': utmCampaign,
      '00N1R00000TX5NG': utmContent,
      '00N1R00000TuA4b': campaignid,
      '00N1R00000TuA4g': keyword,
      '00N1R00000TuA4l': matchtype,
      '00N1R00000TuA4q': adgroupid,
      '00N1R00000TuA4v': creative,
      '00N1R00000TuA55': device,
      Source__c: source,
      GCLID__c: Cookies.get('GCLID') || '',
    };
    fetch(`https://${isProd ? 'webto' : 'test'}.salesforce.com/servlet/servlet.WebToLead?encoding=UTF-8`, {
      method: 'POST',
      body: new URLSearchParams(form),
      mode: 'no-cors',
    })
      .then(() => {
        Cookies.set('get-started-form-complete', true);

        history.push({
          pathname: '/thank-you',
          state: { from: {
            pathname,
            page: currentPage,
          }},
        });

        handleOpenCloseModal();

        pushToDataLayer(
          {
            event: 'Submit "Get Started" Form',
            tutoringRequest: {
              referringUrl: `${window.location.origin}/thank-you`,
              first_name: firstName,
              last_name: lastName,
              email,
              phone,
              zip: zipCode,
              child_age: childAge,
              how_soon: urgency,
              type: tutoringType,
              type_other: tutoringTypeOther,
            },
            salesForce: {
              lead_source: 'Inbound',
              oid: salesforceOID,
              recordType: 'KP Customers',
              company: 'KP Tutors',
              utm_medium: utmMedium,
              utm_source: utmSource,
              utm_campaign: utmCampaign,
              utm_content: utmContent,
              campaignid,
              keyword,
              matchtype,
              adgroupid,
              creative,
              device,
              source,
            },
          }
        );
      })
      .catch((err) => logger.error(err));
  };

  const nextQuestion = (currentStepId) => {
    const validEmail = validateEmail(formValues.email);
    const validZipCode = validateZip(formValues.zipCode);
    const validPhone = validatePhone(formValues.phone);
    const allValid = validEmail && validPhone && validZipCode;

    if (optionSelected === '' && (currentStepId !== 'questionName' && currentStepId !== 'questionEmail' && currentStepId !== 'questionPhone')) {
      messages.push({ component: '', message: 'Please enter an answer or click "Skip" to move on to the next step' });
    }
    else if (currentStepId === 'questionName' && (formValues.firstName === '' || formValues.lastName === '')) {
      messages.push({ component: '', message: 'Please enter your full name' });
    }
    else if (currentStepId === 'questionEmail' && validEmail === false) {
      messages.push({ component: '', message: 'Please enter a valid email' });
    }
    else if (currentStepId === 'questionZip' && validZipCode === false) {
      messages.push({ component: '', message: 'Please enter a valid zip code' });
    }
    else if (currentStepId === 'questionPhone' && validPhone === false) {
      messages.push({ component: '', message: 'Please enter a valid phone number' });
    }

    if (messages.length > 0) {
      setErrorMessages(messages);
      setStep(currentStep);
      return;
    }

    if (isLastStep && allValid) {
      setErrorMessages([]);
      postLeadToSFDC();
      return;
    }

    if (currentStep < totalSteps - 1) {
      setErrorMessages([]);
      setOptionSelected('');
      setStep(currentStep + 1);
    }
  };

  const renderStep = () => {
    switch (stepTest.id) {
    case 'questionChildAge':
      return <ChildAge {...stepProps} />;
    case 'questionType':
      return <TutoringTypeStep {...stepProps} />;
    case 'questionSoon':
      return <UrgencyStep {...stepProps} />;
    case 'questionName':
      return <Step>{header}<FullName {...stepProps} /></Step>;
    case 'questionEmail':
      return <Step>{header}<Email {...stepProps} /></Step>;
    case 'questionZip':
      return <Step>{header}<ZipCode {...stepProps} /></Step>;
    case 'questionPhone':
      return <Step>{header}<PhoneNumber {...stepProps} /></Step>;
    default:
      return <h4>Ooops there was a problem with the form</h4>;
    }
  };

  return (
    <Modal isOpen={showModal} className={classes.modalContainer}>
      <div className={classes.questionsContainer}>
        { currentStep !== totalSteps &&
          (
            <>
              <h6 className={classes.stepCount}>Step { currentStep + 1 } of { totalSteps }</h6>
              <div className={cx(classes.greyContainer, classes.roundedContainer)}>
                <div className={classes.progressContainer} style={{ width: `${getPercentage(currentStep + 1, totalSteps)}%`, borderRadius: `${getPercentage(currentStep + 1, totalSteps) === 100 ? '16px' : '16px 0 0 16px'}` }} />
              </div>
            </>
          ) }
        <form
          style={{ width: '100%' }}
          onSubmit={(event) => { event.preventDefault(); }}
        >
          { renderStep() }
          { isLastStep && <div className={classes.consentMessage}>By submitting this form, you agree to receive text messages and to our <a href="https://kidpass.com/terms" target="_blank" rel="noreferrer">Terms of Service</a> and <a href="https://kidpass.com/privacy" target="_blank" rel="noreferrer">Privacy Policy</a>.</div> }
          <div className={classes.buttonContainer}>
            <a
              className={cx(classes.controlButtons, classes.button)}
              role="button"
              tabIndex="0"
              onClick={(event) => {
                nextQuestion(stepTest.id, event);
              }}
              id={stepTest.nextId}
              onKeyPress={e => e.key === 'Enter' && e.target.click()}
            >{ isLastStep ? 'Submit' : 'Next' }</a>
            { stepTest.skipId && (
              <div
                id={stepTest.skipId}
                className={cx(classes.controlButtons, classes.skipButton)}
                onClick={() => {
                  skipQuestion();
                }}
                tabIndex="0"
                role="button"
                onKeyPress={e => e.key === 'Enter' && e.target.click()}
              >Skip</div>
            ) }
          </div>
        </form>
      </div>
    </Modal>
  );
};

export default GetStartedForm;
