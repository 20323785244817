import { isEmpty } from 'lodash';
import PropTypes from 'prop-types';
import React from 'react';
import { createUseStyles } from 'react-jss';
import cx from 'classnames';
import map from 'lodash/map';

const useStyles = createUseStyles(theme => ({
  alert: {
    color: theme.palette.alert.main,
    display: 'block',
    fontSize: '1.4rem',
    textAlign: 'center',
    borderRadius: 0,
    marginLeft: 'auto',
    marginRight: 'auto',
    opacity: '0.85',
    padding: '15px',
    marginBottom: '20px',
    border: '1px solid transparent',
    '& ul': {
      paddingLeft: 0,
      marginBottom: 0,
      marginTop: 0,
    },
    '& ul > li': {
      listStyleType: 'none',
      display: 'block',
      fontSize:'1.4rem',
    },
    '&.danger': {
      backgroundColor: '#f2dede',
      borderColor: '#ebccd1',
    },
    '&.success':{
      color: theme.palette.success.main,
    },
    '&.visible': {
      display: 'block',
    },
    '&.invisible': {
      display: 'none',
    },
    alertMessage: {
      textAlign:'center',
      color: theme.palette.alert.main,
      display: 'block',
      fontSize: '1.4rem',
    },
  },
}));

const ErrorMessage = (props) => {
  const classes = useStyles();
  const { success, errorMessages, style } = props;

  const renderMessages = (alertMessages) => {
    return (
      <ul>
        { map(alertMessages, (error) => {
          if (!isEmpty(error)) {
            return typeof error.message !== 'undefined'
              ? map(error.message.split('\n'), (message, i) => {
                return (
                  <li key={`error-message-${i}`}>
                    <span
                      className={classes.alertMessage}
                      /* eslint-disable-next-line react/no-danger */
                      dangerouslySetInnerHTML={{ __html: message }}
                    />
                  </li>
                );
              })
              : null;
          }
        })}
      </ul>
    );
  };

  const errors = errorMessages?.length > 0;
  const errorClass = cx(classes.alert, {
    'danger': !success,
    'success': success,
  });

  return (
    errors && (
      <div style={style} className={errorClass} role="alert">
        { renderMessages(errorMessages) }
      </div>
    )
  );
};

ErrorMessage.propTypes = {
  errorMessages: PropTypes.array,
  style: PropTypes.object,
  success: PropTypes.bool,
};

export default ErrorMessage;
