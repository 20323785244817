import React from 'react';
import { Switch, Route, Redirect } from 'react-router-dom';

import {
  Home,
  Landing,
  Team,
  Template,
  Testimonials,
  Thankyou,
} from './pages';

/*
 If you add or modify a search engine indexable route, make sure you update sitemap.js
*/

const Routes = () => {
  return (
    <Switch>
      <Route exact path="/" component={Home} />
      <Route exact path="/en/:format-:subject-:type-:location" component={Landing} />
      <Route exact path="/thank-you" component={Thankyou}/>
      <Route exact path="/team" component={Team} />
      <Route exact path="/:path(tutoring|classes)/:filter1/:filter2?" component={Template} />
      <Route exact path="/testimonials" component={Testimonials} />
      <Route path="*" render={() => <Redirect to="/" />} />
    </Switch>
  );
};

export default Routes;
