const jennyImage = require('./assets/testimonial-jenny.webp');
const alexImage = require('./assets/testimonial-alex.webp');
const michelleImage = require('./assets/testimonial-michelle.webp');
const teresaImage = require('./assets/testimonial-teresa.webp');
const menaImage = require('./assets/testimonial-mena.webp');
const danaImage = require('./assets/testimonial-dana.webp');

const testimonialItems = [
  {
    message: '“Before starting my KidPass Tutors package, I was helping my daughter with her homework every day. Now, not only is she having an easier time completing her homework (and getting better grades!), but she’s also becoming a better reader!”',
    image: jennyImage,
    name: 'Jenny',
    bio: 'Parent to 10-year-old',
  },
  {
    message: '“My daughter is 4 years old and we wanted to prepare her for starting kindergarten in the fall. After a couple of months with KidPass Tutors, she is already comfortable with a lot of reading and counting skills, which is a massive source of help and comfort for us as parents.”',
    image: alexImage,
    name: 'Alex',
    bio: 'Parent to 4-year-old',
  },
  {
    message: '“Due to the pandemic, my son fell behind in every subject. With KidPass Tutors, the immediate change has been a boost in his confidence. We’ve heard from his teacher that he’s participating more in class and answering questions previously outside his comfort zone!”',
    image: michelleImage,
    name: 'Michelle',
    bio: 'Parent to 6-year-old',
  },
  {
    message: '“After two months of KidPass Tutors, my son was able to read beginner books. I was shocked! It made him feel like a “big kid” and—most importantly—he is very proud of himself.”',
    image: teresaImage,
    name: 'Teresa',
    bio: 'Parent to 6-year-old',
  },
  {
    message: '“KidPass Tutors is the best. My daughter and I used to fight all through homework time, but now she is keeping pace with her curriculum without me. Thanks, KidPass Tutors!”',
    image: menaImage,
    name: 'Mena',
    bio: 'Parent to 12-year-old',
  },
  {
    message: '“My son was struggling to meet basic standards in math on his first report card and needed extra help with reading comprehension. With KidPass Tutors, he’s tangibly improved and is now meeting his standards!”',
    image: danaImage,
    name: 'Dana',
    bio: 'Parent to 7-year-old',
  },
];

export default testimonialItems;
