import React from 'react';
import PropTypes from 'prop-types';
import { createUseStyles } from 'react-jss';

const useStyles = createUseStyles((theme)=>({
  checkboxWrapper: {
    '& label': {
      cursor: 'pointer',
      display: 'flex',
      justifyContent: 'left',
      alignItems: 'center',
      marginBottom: 0,
      fontSize: '1.6rem',
      lineHeight: '2.2rem',
    },
    '& input': {
      margin: '4px 0 0',
      lineHeight: 'normal',
      opacity: 0,
      cursor: 'pointer',
      width: 0,
      padding:0,
      '&:focus-visible + span.custom-checkbox': {
        outline: 'none !important',
        boxShadow: '0 0 0 2px rgba(0, 180, 240, .5)',
      },
    },
    '& span.custom-checkbox': {
      height: '20px',
      width: '20px',
      minWidth: '20px',
      borderRadius: '4px',
      border: '1px solid #d7d7d7',
      backgroundColor: '#fff',
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      marginRight: '8px',
    },
    '& input:checked ~ span.custom-checkbox': {
      backgroundColor: theme.palette.primary.main,
      borderColor: theme.palette.primary.main,
      borderRadius: '4px',
      transform: 'rotate(0deg) scale(1)',
      opacity:1,
    },
    '& .custom-checkbox:after': {
      content: '""',
      height: 0,
      width: 0,
      borderRadius: '5px',
      border: '1px solid #fff',
      borderWidth: '0 3px 3px 0',
      transform: 'rotate(0deg) scale(0)',
      opacity: 1,
      marginBottom: '3px',
    },
    '& input:checked ~ .custom-checkbox:after': {
      transform: 'rotate(45deg) scale(1)',
      opacity: 1,
      width: '6px',
      height: '12px',
      border: 'solid #fff',
      borderWidth: '0 2px 2px 0',
      backgroundColor: 'transparent',
      borderRadius: 0,
    },
  },
  customCheckboxLabel: {
    display: 'flex',
    alignItems: 'center',
  },
}));

const CustomCheckbox = (props) => {
  const classes = useStyles();

  const {
    id,
    ...rest
  } = props;
  return (
    <div key={props.id} className={classes.checkboxWrapper} id={props.id} {...rest}>
      <label>
        <input
          type="checkbox"
          tabIndex="0"
          onChange={props.onChange}
          checked={props.checked}
          value={props.value}
          name={props.name}
          onKeyPress={e => e.key === 'Enter' && e.target.click()}
        />
        <span className="custom-checkbox"/>
        <span className={classes.customCheckboxLabel}>
          <span>{ props.label }</span>
        </span>
      </label>
    </div>
  );
};

CustomCheckbox.propTypes = {
  value: PropTypes.string || PropTypes.number,
  name: PropTypes.string,
  label: PropTypes.string,
  checked: PropTypes.bool,
  onChange: PropTypes.func,
  id: PropTypes.string,
  rest: PropTypes.object,
};

export default CustomCheckbox;
