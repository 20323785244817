import PropTypes from 'prop-types';
import React from 'react';
import { createUseStyles } from 'react-jss';

export const maxWidth = 390;

const useStyles = createUseStyles(theme => ({
  subject: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    textAlign: 'center',
    maxWidth: `${maxWidth}px`,
    '& > h1': {
      fontSize: '20px',
      lineHeight: 1.4,
      fontWeight: theme.fontWeight.medium,
      [theme.mediaRule('MEDIUM_UP')]: {
        fontSize: '24px',
        lineHeight: 1.33,
        marginBottom: theme.spacing(2),
      },
    },
  },
  icon: {
    height: '64px',
    width: '102px',
    backgroundRepeat: 'no-repeat',
    backgroundSize: 'contain',
    backgroundPosition: 'center center',
    marginBottom: theme.spacing(3),
    [theme.mediaRule('MEDIUM_UP')]: {
      marginBottom: theme.spacing(4),
    },
  },
  description: {
    display: 'none',
    [theme.mediaRule('MEDIUM_UP')]: {
      fontSize: '20px',
      lineHeight: 1.4,
      display: 'block',
    },
  },
}));

const Subject = (props) => {
  const {
    id,
    icon,
    iconLabel,
    subject,
    description,
  } = props;
  const classes = useStyles();

  return (
    <div id={id} className={classes.subject}>
      <div
        id={`${id}Icon`}
        className={classes.icon}
        style={{ backgroundImage: `url(${icon})` }}
        role="img"
        aria-label={iconLabel ? `Icon of ${iconLabel}` : undefined}
      />
      <h1 id={`${id}Title`}>{ subject }</h1>
      <div id={`${id}Message`} className={classes.description}>{ description }</div>
    </div>
  );
};

Subject.propTypes = {
  id: PropTypes.string.isRequired,
  icon: PropTypes.string.isRequired,
  iconLabel: PropTypes.oneOf([PropTypes.string, PropTypes.node]),
  subject: PropTypes.oneOf([PropTypes.string, PropTypes.node]).isRequired,
  description: PropTypes.oneOf([PropTypes.string, PropTypes.node]).isRequired,
};

export default Subject;
