import PropTypes from 'prop-types';
import React from 'react';
import { createUseStyles, useTheme } from 'react-jss';
import { useMediaQuery } from '@react-hook/media-query';

import Button from '../../Button';
import { useStoreContext } from '../../../StoreContext';

const sealImage = require('./assets/kp-guarantee.webp');

const useStyles = createUseStyles(theme => ({
  satisfactionGuarantee: {
    width: '100%',
    background: theme.palette.yellow.lightest,
  },
  inner: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    textAlign: 'center',
    margin: '0 auto',
    padding: theme.spacing('80px', 4),
    maxWidth: `${theme.sectionMaxWidth}px`,
    [theme.mediaRule('MEDIUM_UP')]: {
      padding: theme.spacing('120px', 4),
      '& button': {
        width: '250px',
      },
    },
    [theme.mediaRule('LARGE_UP')]: {
      paddingLeft: '50px',
      paddingRight: '50px',
    },
    '& h1': {
      fontSize: '28px',
      lineHeight: 1.29,
      fontWeight: theme.fontWeight.medium,
      marginBottom: theme.spacing(3),
      [theme.mediaRule('MEDIUM_UP')]: {
        fontSize: '32px',
        lineHeight: 1.25,
      },
    },
  },
  seal: {
    width: '100%',
    maxWidth: '226px',
    height: '155px',
    imageRendering: '-webkit-optimize-contrast',
    backgroundImage: `url(${sealImage})`,
    backgroundRepeat: 'no-repeat',
    backgroundSize: 'contain',
    backgroundPosition: 'center center',
    marginBottom: theme.spacing(4),
    [theme.mediaRule('MEDIUM_UP')]: {
      maxWidth: '292px',
      height: '200px',
      marginBottom: theme.spacing(6),
    },
  },
  message: {
    fontSize: '20px',
    lineHeight: 1.4,
    marginBottom: theme.spacing(4),
    maxWidth: '900px',
    [theme.mediaRule('MEDIUM_UP')]: {
      fontSize: '24px',
      lineHeight: 1.33,
      marginBottom: theme.spacing(6),
    },
  },
}));

const SatisfactionGuarantee = (props) => {
  const {
    locationId = '',
  } = props;
  const classes = useStyles();
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.MEDIUM_DOWN);
  const { handleOpenCloseModal } = useStoreContext();

  return (
    <section id="satisfactionGuaranteeSection" className={classes.satisfactionGuarantee}>
      <div className={classes.inner}>
        <div id="satisfactionGuaranteeSealImage" className={classes.seal} role="img" aria-label="Satisfaction Guarantee Seal" />
        <h1 id="satisfactionGuaranteeTitle">100% Satisfaction Guarantee</h1>
        <div id="satisfactionGuaranteeMessage" className={classes.message}>
          KidPass Tutors By Begin is built on a thriving community of happy learners and expert tutors.
          We work hard to match learners with the best tutors.
          If for some reason your child needs to be rematched with a new tutor, we’ll work with you to find the right tutor for your child’s needs.
          We proudly stand by our service, and we are certain that you’ll be satisfied with your experience.
        </div>
        <Button id={`buttonSatisfaction${locationId}`} variant="contained" fullWidth={isMobile} onClick={handleOpenCloseModal}>Get Started</Button>
      </div>
    </section>
  );
};

SatisfactionGuarantee.propTypes = {
  locationId: PropTypes.string,
};

export default SatisfactionGuarantee;
