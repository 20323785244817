import React from 'react';
import PropTypes from 'prop-types';
import { createUseStyles } from 'react-jss';

const useStyles = createUseStyles(theme => ({
  input: {
    width: '100%',
    borderRadius: '4px',
    border: '1px solid #d7d7d7',
    marginTop: '0.8rem',
    height: '3.8rem',
    padding: '0 1rem',
    fontSize: '1.6rem',
    fontWeight: theme.fontWeight.normal,
  },
}));

const Input = (props) => {
  const {
    ...rest
  } = props;
  const classes = useStyles();
  return (
    <input className={classes.input} {...rest}></input>
  );
};

Input.propTypes = {
  rest: PropTypes.object,
};

export default Input;
