import PropTypes from 'prop-types';
import React from 'react';
import { createUseStyles, useTheme } from 'react-jss';
import { useMediaQuery } from '@react-hook/media-query';

import { useStoreContext } from '../../StoreContext';
import Button from '../Button';

const useStyles = createUseStyles(theme => ({
  about: {
    width: '100%',
  },
  inner: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    textAlign: 'center',
    margin: '0 auto',
    padding: theme.spacing('40px', 4, '100px', 4),
    maxWidth: `${theme.sectionMaxWidth}px`,
    [theme.mediaRule('MEDIUM_UP')]: {
      padding: theme.spacing('60px', 4, '120px', 4),
      '& button': {
        width: '250px',
      },
    },
    [theme.mediaRule('LARGE_UP')]: {
      paddingLeft: '50px',
      paddingRight: '50px',
    },
    '& h1': {
      fontSize: '28px',
      lineHeight: 1.29,
      fontWeight: theme.fontWeight.medium,
      marginBottom: theme.spacing(2),
      maxWidth: '800px',
      [theme.mediaRule('MEDIUM_UP')]: {
        fontSize: '32px',
        lineHeight: 1.25,
        marginBottom: theme.spacing(3),
      },
    },
  },
  message: {
    fontSize: '20px',
    lineHeight: 1.4,
    marginBottom: theme.spacing(4),
    maxWidth: '900px',
    [theme.mediaRule('MEDIUM_UP')]: {
      fontSize: '24px',
      lineHeight: 1.33,
      marginBottom: theme.spacing(6),
    },
  },
}));

const About = (props) => {
  const {
    title,
    message,
    locationId = '',
  } = props;
  const classes = useStyles();
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.MEDIUM_DOWN);
  const { handleOpenCloseModal } = useStoreContext();

  return (
    <section id="aboutSection" className={classes.about}>
      <div className={classes.inner}>
        <h1 id="aboutTitle">{ title }</h1>
        <div id="aboutMessage" className={classes.message}>{ message }</div>
        <Button id={`buttonAbout${locationId}`} variant="contained" fullWidth={isMobile} onClick={handleOpenCloseModal}>Get Started</Button>
      </div>
    </section>
  );
};

About.propTypes = {
  title: PropTypes.string.isRequired,
  message: PropTypes.string.isRequired,
  locationId: PropTypes.string,
};

export default About;
