import React from 'react';
import { createUseStyles } from 'react-jss';

const useStyles = createUseStyles(theme => ({
  section: {
    width: '100%',
    background: theme.palette.yellow.lightest,
  },
  inner: {
    textAlign: 'center',
    padding: theme.spacing('80px', 4),
    maxWidth: `${theme.sectionMaxWidth}px`,
    margin: '0 auto',
    [theme.mediaRule('MEDIUM_UP')]: {
      padding: theme.spacing('100px', 4),
      '& button': {
        width: '200px',
      },
    },
    [theme.mediaRule('LARGE_UP')]: {
      paddingLeft: '50px',
      paddingRight: '50px',
    },
  },
  metricsWrapper: {
    display: 'flex',
    flexDirection: 'column',
    gap: '60px',
    alignItems: 'center',
    [theme.mediaRule('LARGE_UP')]: {
      flexDirection: 'row',
      gap: '135px',
      justifyContent: 'center',
    },
  },
  metric: {
    flex: 1,
    maxWidth: '300px',
    display: 'flex',
    flexDirection: 'column',
    gap: theme.spacing(1),
  },
  number: {
    color: theme.palette.primary.main,
    fontWeight: theme.fontWeight.bold,
    fontSize: '48px',
    lineHeight: 1.17,
    [theme.mediaRule('MEDIUM_UP')]: {
      fontSize: '60px',
      lineHeight: 1.2,
    },
  },
  title: {
    fontSize: '20px',
    lineHeight: 1.4,
    [theme.mediaRule('MEDIUM_UP')]: {
      fontSize: '24px',
      lineHeight: 1.33,
    },
  },
}));

const Metrics = () => {
  const classes = useStyles();

  return (
    <section className={classes.section}>
      <div className={classes.inner}>
        <div className={classes.metricsWrapper}>
          <div className={classes.metric}>
            <span className={classes.number}>500,000+</span>
            <span className={classes.title}>sessions held</span>
          </div>

          <div className={classes.metric}>
            <span className={classes.number}>300+</span>
            <span className={classes.title}>subjects</span>
          </div>

          <div className={classes.metric}>
            <span className={classes.number}>99%</span>
            <span className={classes.title}>client satisfaction</span>
          </div>
        </div>
      </div>
    </section>
  );
};

export default Metrics;
