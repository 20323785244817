const { datadogRum } = require('@datadog/browser-rum');

const { mapEnvToDD, getVersion } = require('./envMappingTools');

const configDataDogRum = (serviceName) => {
  const configuration = {
    sampleRate: 100,
    premiumSampleRate: 100,
    trackInteractions: true,
    defaultPrivacyLevel:'mask-user-input',
    // Application ID and Client Token are considered public data and not "secrets".
    applicationId: '803a016a-1238-43f9-b3d5-acc1d7741005',
    clientToken: 'pub661d176745a2c73a912a41c602d09095',
    site: 'datadoghq.com',
    service: serviceName,
    env: mapEnvToDD(process.env.NODE_ENV),
    // Specify a version number to identify the deployed version of your application in Datadog
    version: getVersion(),
  };
  datadogRum.init(configuration);
  datadogRum.startSessionReplayRecording();
  // eslint-disable-next-line no-console
  console.log(`RUM monitor started in environment [${configuration.env}] for version [${configuration.version}]`);
};

module.exports = configDataDogRum;
