import React, { useState, useCallback, useEffect } from 'react';
import { createUseStyles, useTheme } from 'react-jss';
import { useMediaQuery } from '@react-hook/media-query';
import cx from 'classnames';

import testimonialItems from '../../../components/testimonials/testimonialItems';
import TestimonialBox, { maxWidth as testimonialBoxWidth } from '../../../components/testimonials/TestimonialBox';

const moreArrowIcon = require('../assets/more-arrow.svg');
const lessArrowIcon = require('../assets/less-arrow.svg');

const useStyles = createUseStyles(theme => ({
  testimonials: {
    width: '100%',
    background: theme.palette.yellow.lightest,
  },
  inner: {
    textAlign: 'center',
    padding: theme.spacing('80px', 6),
    maxWidth: `${theme.sectionMaxWidth}px`,
    margin: '0 auto',
    [theme.mediaRule('MEDIUM_UP')]: {
      padding: theme.spacing('120px', 4),
    },
    '& > h1': {
      fontSize: '28px',
      lineHeight: 1.29,
      fontWeight: theme.fontWeight.medium,
      marginBottom: theme.spacing(4),
      [theme.mediaRule('MEDIUM_UP')]: {
        fontSize: '32px',
        lineHeight: 1.25,
        marginBottom: theme.spacing(6),
      },
    },
    [theme.mediaRule('LARGE_UP')]: {
      paddingLeft: '50px',
      paddingRight: '50px',
    },
  },
  testimonialsWrapper: {
    display: 'flex',
    flexDirection: 'column',
    gap: theme.spacing(4),
    alignItems: 'center',
    margin: '0 auto',
    [theme.mediaRule('MEDIUM_UP')]: {
      gap: theme.spacing(7),
      display: 'grid',
      gridTemplateColumns: 'repeat(2, 1fr)',
      maxWidth: `${(testimonialBoxWidth * 2) + (theme.baseSpace * 7)}px`,
      '& > div': {
        height: '100%',
      },
    },
    [theme.mediaRule('NAVIGATION_DESKTOP')]: {
      maxWidth: `${(testimonialBoxWidth * 3) + (theme.baseSpace * 7 * 2)}px`,
      gridTemplateColumns: 'repeat(3, 1fr)',
    },
  },
  showMore: {
    textAlign: 'center',
    fontSize: '16px',
    lineHeight: 1.38,
    marginTop: theme.spacing(8),
    '& > span': {
      cursor: 'pointer',
      color: theme.palette.primary.main,
      '&:hover, &:focus-visible': {
        color: theme.palette.primary.dark,
      },
    },
  },
  showMoreArrow: {
    display: 'inline-block',
    width: '12px',
    height: '8px',
    marginLeft: '8px',
    verticalAlign: 'middle',
    maskSize: 'contain',
    maskRepeat: 'no-repeat',
    backgroundColor: theme.palette.primary.main,
    'span:hover > &, span:focus-visible > &': {
      backgroundColor: theme.palette.primary.dark,
    },
    '&.more': {
      maskImage: `url(${moreArrowIcon})`,
    },
    '&.less': {
      maskImage: `url(${lessArrowIcon})`,
    },
  },
}));

const Testimonials = () => {
  const classes = useStyles();
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.MEDIUM_DOWN);
  const [showMore, setShowMore] = useState(false);

  const toggleShowMore = useCallback(() => {
    setShowMore((prevShowMore) => !prevShowMore);
  }, []);

  useEffect(() => {
    setShowMore(false);
  }, [isMobile]);

  return (
    <section className={classes.testimonials}>
      <div className={classes.inner}>
        <h1>See What Parents Are Saying In Their Own Words</h1>
        <div className={classes.testimonialsWrapper}>
          { testimonialItems.slice(0, isMobile && !showMore ? 3 : testimonialItems.length).map((item) => (
            <TestimonialBox key={item.name} {...item} />
          ))}
        </div>
        { isMobile && (
          <div className={classes.showMore}>
            <span
              role="button"
              tabIndex="0"
              onKeyPress={e => e.key === 'Enter' && e.target.click()}
              onClick={toggleShowMore}
            >
              {`View ${showMore ? 'fewer' : 'more'} reviews`}
              <div className={cx(classes.showMoreArrow, showMore ? 'less' : 'more')} />
            </span>
          </div>
        )}
      </div>
    </section>
  );
};

export default Testimonials;
