import React from 'react';
import { createUseStyles } from 'react-jss';
import 'slick-carousel/slick/slick.css';
import Slider from 'react-slick';

import CarouselArrow from '../CarouselArrow';
import testimonialItems from '../testimonials/testimonialItems';
import TestimonialBox, { maxWidth as testimonialBoxWidth } from '../testimonials/TestimonialBox';

const useStyles = createUseStyles(theme => ({
  testimonials: {
    width: '100%',
    background: theme.palette.yellow.lightest,
  },
  inner: {
    textAlign: 'center',
    padding: theme.spacing('80px', 6),
    maxWidth: `${theme.sectionMaxWidth}px`,
    margin: '0 auto',
    '& > h1': {
      fontSize: '28px',
      lineHeight: 1.29,
      fontWeight: theme.fontWeight.medium,
      marginBottom: theme.spacing(4),
      [theme.mediaRule('MEDIUM_UP')]: {
        fontSize: '32px',
        lineHeight: 1.25,
        marginBottom: theme.spacing(6),
      },
    },
    '& .slick-list': {
      height: 'unset !important', // override issue with toggling adaptiveHeight true/false
      width: 'calc(100% + 35px)',
      marginLeft: '-17.5px',
    },
    '& .slick-track': {
      display: 'flex',
    },
    '& .slick-slide': {
      height: 'auto',
    },
    '& .slick-slide > div': {
      height: '100%',
      padding: '0 17.5px',
    },
    '& .slick-slide > div > div': {
      height: '100%',
    },
    [theme.mediaRule('MEDIUM_DOWN')]: {
      '& .slick-slider': {
        maxWidth: `${testimonialBoxWidth}px`,
        margin: '0 auto',
      },
    },
    [theme.mediaRule('MEDIUM_UP')]: {
      padding: theme.spacing('120px', '35px'),
    },
    [theme.mediaRule('LARGE_UP')]: {
      paddingLeft: '50px',
      paddingRight: '50px',
    },
    '@media only screen and (min-width: 767px) and (max-width: 1200px)': {
      '& .slick-slide > div > div': {
        width: '100%',
        maxWidth: 'unset',
      },
    },
  },
}));

const Testimonials = () => {
  const classes = useStyles();

  return (
    <section id="testimonialsSection" className={classes.testimonials}>
      <div className={classes.inner}>
        <h1 id="testimonialsTitle">Parents Love Us!</h1>
        <div id="testimonialsCarouselWrapper">
          <Slider
            infinite
            speed={500}
            slidesToShow={3}
            slidesToScroll={3}
            adaptiveHeight={false}
            responsive={[
              {
                breakpoint: 1200,
                settings: {
                  slidesToShow: 2,
                  slidesToScroll: 2,
                  adaptiveHeight: false,
                },
              },
              {
                breakpoint: 767,
                settings: {
                  slidesToShow: 1,
                  slidesToScroll: 1,
                  adaptiveHeight: false,
                },
              },
            ]}
            prevArrow={<CarouselArrow type="prev" />}
            nextArrow={<CarouselArrow type="next" />}
          >
            { testimonialItems.map((item, index) => (
              <TestimonialBox id={`testimonialsBox${index + 1}`} key={item.name} {...item} />
            ))}
          </Slider>
        </div>
      </div>
    </section>
  );
};

export default Testimonials;
