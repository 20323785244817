import React from 'react';
import { createUseStyles } from 'react-jss';

const teamPhoto1 = require('../assets/team-photo-1.webp');
const teamPhoto2 = require('../assets/team-photo-2.webp');
const teamPhoto3 = require('../assets/team-photo-3.webp');
const teamPhoto4 = require('../assets/team-photo-4.webp');

const useStyles = createUseStyles(theme => ({
  section: {
    width: '100%',
  },
  inner: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    textAlign: 'center',
    margin: '0 auto',
    padding: theme.spacing('100px', 4),
    maxWidth: `${theme.sectionMaxWidth}px`,
    [theme.mediaRule('MEDIUM_UP')]: {
      padding: theme.spacing('180px', 4),
    },
    [theme.mediaRule('LARGE_UP')]: {
      paddingLeft: '50px',
      paddingRight: '50px',
    },
    '& > h1': {
      fontSize: '28px',
      lineHeight: 1.29,
      fontWeight: theme.fontWeight.medium,
      marginBottom: theme.spacing(2),
      [theme.mediaRule('MEDIUM_UP')]: {
        fontSize: '32px',
        lineHeight: 1.25,
        marginBottom: theme.spacing(3),
      },
    },
  },
  message: {
    fontSize: '20px',
    lineHeight: 1.4,
    marginBottom: theme.spacing(4),
    maxWidth: '900px',
    [theme.mediaRule('MEDIUM_UP')]: {
      fontSize: '24px',
      lineHeight: 1.33,
      marginBottom: theme.spacing(6),
    },
    '& a': {
      color: theme.palette.text.main,
    },
  },
  imageGrid: {
    display: 'grid',
    gap: theme.spacing(2),
    gridTemplateColumns: 'repeat(2, 1fr)',
    gridTemplateRows: 'repeat(4, 1fr)',
    maxWidth: '1240px',
    margin: '0 auto',
    marginBottom: '100px',
    [theme.mediaRule('MEDIUM_UP')]: {
      gap: theme.spacing(4),
      gridTemplateColumns: 'repeat(4, 1fr)',
      gridTemplateRows: 'repeat(2, 1fr)',
      marginBottom: '180px',
    },
  },
  image1: {
    gridColumn: '1 / 3',
    gridRow: 1,
  },
  image2: {
    gridColumn: 1,
    gridRow: 2,
  },
  image3: {
    gridColumn: 2,
    gridRow: 2,
  },
  image4: {
    gridColumn: '1 / 3',
    gridRow: '3 / 5',
    [theme.mediaRule('MEDIUM_UP')]: {
      gridColumn: '3 / 5',
      gridRow: '1 / 3',
    },
  },
}));

const MeetOurTeam = () => {
  const classes = useStyles();

  return (
    <section className={classes.section}>
      <div className={classes.inner}>
        <h1>Meet Our Team</h1>
        <div className={classes.message}>
          We’re a team of impact driven leaders and passionate learning experts who are working tirelessly to connect and support learners and tutors.
        </div>
        <div className={classes.imageGrid}>
          <img src={teamPhoto1} className={classes.image1} alt="The KidPass Tutors team enjoying a drink" />
          <img src={teamPhoto2} className={classes.image2} alt="The BEGiN team celebrating" />
          <img src={teamPhoto3} className={classes.image3} alt="The BEGiN team working remotely" />
          <img src={teamPhoto4} className={classes.image4} alt="The BEGiN team hanging out" />
        </div>

        <h1>Meet Begin, the Company Behind KidPass Tutors</h1>
        <div className={classes.message} style={{ maxWidth: '1136px' }}>
          <p style={{marginBottom: '1.65em'}}>
            Begin is the award-winning early learning company offering children the best start possible through digital, physical, and experiential learning programs.
            With play-based products including KidPass, HOMER, codeSpark Academy, and Little Passports, Begin builds the skills that matter most to help kids achieve their fullest potential in school and life.
          </p>

          <p>
            Begin is backed by the most recognized names in early childhood development, including LEGO Ventures, Sesame Workshop, and Gymboree Play & Music.
            For more information about Begin and its suite of integrated programs, visit <a href="www.beginlearning.com" target="_blank">www.beginlearning.com</a>.
          </p>
        </div>
      </div>
    </section>
  );
};

export default MeetOurTeam;
