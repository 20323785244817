import React, { useState, useEffect } from 'react';
import { useParams, useLocation } from 'react-router-dom';
import { createUseStyles } from 'react-jss';

import {
  About,
  ContactUs,
  FeaturedIn,
  Features,
  Hero,
  InspireAndAdvance,
  SatisfactionGuarantee,
  Subjects,
  Testimonials,
  TrustPilot,
  Tutors,
} from '../components/sections';
import { useStoreContext } from '../StoreContext';
import { useHelmet } from '../utils';

const useStyles = createUseStyles(theme => ({
  landing: {
    fontSize: '16px',
  },
  whiteButtonOveride: {
    background: 'white',
    color: theme.palette.primary.main,
    '&:hover, &:focus-visible, &:active': {
      background: 'white',
      color: theme.palette.primary.dark,
    },
  },
  '@global': {
    'body header':{
      '& [class^="navWrapper"] > nav, & [class^="hamburgerIcon"]':{
        display: 'none',
      },
    },
    '[class*="homeHero"] h1': {
      maxWidth: 'none',
    },
    'section[class^="about"]': {
      background: theme.palette.yellow.lightest,
    },
    'section[class^="satisfactionGuarantee"]': {
      background: 'white',
    },
    'section[class^="about"] > [class^="inner"]': {
      paddingTop: '80px',
      paddingBottom: '80px',
      [theme.mediaRule('MEDIUM_UP')]: {
        paddingTop: '120px',
        paddingBottom: '120px',
      },
    },
  },
}));

const normalizeSubjectOrFormat = (str) => {
  return str?.toLowerCase()?.trim()?.replaceAll(/\s+/g, '-') || '';
};

const normalizeParam = (param) => {
  return (param?.replaceAll('_', '-')?.trim() || '');
};

const Landing = () => {
  const classes = useStyles();
  const params = useParams();
  const { dispatch } = useStoreContext();
  const { pathname } = useLocation();

  const defaultAboutMessage = `
    1-on-1 private tutoring is the most personalized and effective way to learn.
    We have expert tutors that can help your child master key concepts and excel in class through custom learning plans based on your child’s needs.
    Tutors on our platform are certified teachers who understand scope and sequence, as well as how to engage and motivate students.
  `;

  const [aboutMessage, setAboutMessage] = useState(defaultAboutMessage);

  const format = params.format !== 'all_form' ? normalizeParam(params.format) : '';
  const subject = params.subject !== 'all_subj' ? normalizeParam(params.subject) : '';
  const type = normalizeParam(params.type);
  const location = params.location !== 'all_locs' ? normalizeParam(params.location) : '';

  const helmet = useHelmet({
    title: `Top ${[format, subject].filter(Boolean).join(' ')} Tutors ${location ? `in ${location} ` : ''}| KidPass Tutors`,
    description: `Choose From the Best ${[format, subject].filter(Boolean).join(' ')} Tutors${location ? ` Near You in ${location}` : ''}. Personalized, Private Tutoring Available for All Ages.`,
    breadcrumbs: {[[format, subject, type].filter(Boolean).join(' ')]: pathname },
    robots: 'noindex, follow',
  });

  const getDynamicAboutMessage = async () => {
    if (subject || format) {
      try {
        const data = await import(`./Template/data/${normalizeSubjectOrFormat(subject || format)}.json`);
        if (data?.message) {
          setAboutMessage(data.message);
        }
        /* eslint-disable-next-line no-empty */
      } catch (e) {}
    }
  };

  useEffect(() => {
    dispatch({ type: 'set-current-page', payload: 'landing' });
    getDynamicAboutMessage();
  }, []);

  useEffect(() => {
    getDynamicAboutMessage();
  }, [subject, format]);

  const heroPrefix = subject || format || '';

  return (
    <div className={classes.landing}>
      {helmet}
      <Hero.Home title={`${heroPrefix ? heroPrefix + ' ' : ''}Tutoring for Young Learners`} locationId="Landing" />
      <FeaturedIn />
      <TrustPilot />
      <InspireAndAdvance locationId="Landing" />
      <Testimonials />
      <Subjects locationId="Landing" />
      <Tutors />
      <Features />
      <About
        title={`Why Choose KidPass Tutors for ${subject || format || ''} Tutoring`}
        message={aboutMessage}
        locationId="Landing"
      />
      <SatisfactionGuarantee locationId="Landing" />
      <ContactUs locationId="Landing" />
    </div>
  );
};

export default Landing;
