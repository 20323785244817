import { useTheme } from 'react-jss';
import { useMediaQuery } from '@react-hook/media-query';
import { useLocation } from 'react-router-dom';

import { useStoreContext } from '../StoreContext';
import useQuery from './useQuery';

const landingAdGroups = {
  // mobile
  '136307826141': '(888) 488-4443', // English | 0 - 10
  '136307827101': '(888) 417-6043', // English | 11 - 50
  '145446838931': '(888) 418-3977', // English | 51 - 100
  '136307826621': '(888) 418-9701', // English | Unknown
  '136307826181': '(888) 438-1508', // Math | 0 - 10
  '136307827141': '(888) 237-0185', // Math | 11 - 50
  '145446838531': '(888) 284-6481', // Math | 51 - 100
  '136307826661': '(888) 285-8095', // Math | Unknown
  '136307826421': '(888) 655-1599', // Tutoring Near Me | 0 - 10
  '136307827381': '(888) 712-3052', // Tutoring Near Me | 11 - 50
  '145446838771': '(888) 760-8832', // Tutoring Near Me | 51 - 100
  '136307826901': '(888) 782-7377', // Tutoring Near Me | Unknown
  '136307825981': '(888) 519-5022', // Online Tutoring | 0 - 10
  '136307826941': '(888) 850-3462', // Online Tutoring | 11 - 50
  '145446838491': '(888) 893-0469', // Online Tutoring | 51 - 100
  '136307826461': '(888) 893-0620', // Online Tutoring | Unknown
  '136307826221': '(888) 507-4582', // Reading | 0 - 10
  '136307827181': '(888) 370-8646', // Reading | 11 - 50
  '145446838691': '(888) 376-2559', // Reading | 51 - 100
  '136307826701': '(888) 381-6788', // Reading | Unknown
  '136307826381': '(888) 548-8696', // Tutoring | 0 - 10
  '136307827341': '(888) 549-7944', // Tutoring | 11 - 50
  '145446838731': '(888) 567-2949', // Tutoring | 51 - 100
  '136307826861': '(888) 655-1385', // Tutoring | Unknown

  // desktop
  '147894606228': '(888) 502-0653', // English | 0 - 10
  '147894607188': '(888) 491-3672', // English | 11 - 50
  '145446838051': '(888) 496-8019', // English | 51 - 100
  '147894606708': '(888) 501-7297', // English | Unknown
  '147894606388': '(888) 465-5411', // Math | 0 - 10
  '147894607348': '(888) 317-2614', // Math | 11 - 50
  '145446838211': '(888) 336-1605', // Math | 51 - 100
  '147894606868': '(888) 351-4763', // Math | Unknown
  '147894606628': '(888) 680-2761', // Tutoring Near Me | 0 - 10
  '147894607588': '(888) 809-0734', // Tutoring Near Me | 11 - 50
  '145446838451': '(888) 811-6591', // Tutoring Near Me | 51 - 100
  '147894607108': '(888) 850-1836', // Tutoring Near Me | Unknown
  '147894606188': '(888) 540-4864', // Online Tutoring | 0 - 10
  '147894607148': '(888) 928-1180', // Online Tutoring | 11 - 50
  '145446838011': '(888) 691-1224', // Online Tutoring | 51 - 100
  '147894606668': '(888) 970-2191', // Online Tutoring | Unknown
  '147894606428': '(888) 509-3032', // Reading | 0 - 10
  '147894607388': '(888) 391-5098', // Reading | 11 - 50
  '145446838251': '(888) 399-8468', // Reading | 51 - 100
  '147894606908': '(888) 415-6491', // Reading | Unknown
  '147894606468': '(888) 570-5147', // Tutoring | 0 - 10
  '147894607428': '(888) 655-1815', // Tutoring | 11 - 50
  '145446838291': '(888) 705-6278', // Tutoring | 51 - 100
  '147894606948': '(888) 712-0016', // Tutoring | Unknown
};

function useContactPhone() {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.MEDIUM_DOWN);
  let { state: { currentPage } } = useStoreContext();
  const { state } = useLocation();
  const query = useQuery();

  if (currentPage === 'thank-you') {
    currentPage = state?.from?.page;
  }

  if (currentPage === 'landing') {
    const adGroupId = query.get('adgroupid');

    return landingAdGroups[adGroupId] || (isMobile ? '(888) 754-1379' : '(888) 754-1382');
  }

  if (currentPage !== 'home') {
    return isMobile ? '(888) 908-0360' : '(888) 890-3897';
  }

  return isMobile ? '(888) 879-0467' : '(888) 841-0487';
}

export default useContactPhone;
