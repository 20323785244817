import PropTypes from 'prop-types';
import React from 'react';
import { createUseStyles, useTheme } from 'react-jss';
import cx from 'classnames';
import { useMediaQuery } from '@react-hook/media-query';

import { useStoreContext } from '../../../StoreContext';
import Button from '../../Button';

const useStyles = createUseStyles(theme => ({
  hero: {
    width: '100%',
    height: '600px',
    backgroundSize: 'cover',
    backgroundRepeat: 'no-repeat',
    backgroundPosition: 'center center',
    [theme.mediaRule('MEDIUM_UP')]: {
      height: '450px',
    },
  },
  inner: {
    height: '100%',
    width: '100%',
    maxWidth: `${theme.sectionMaxWidth}px`,
    padding: theme.spacing(7, 4, 8, 4),
    margin: '0 auto',
    display: 'flex',
    [theme.mediaRule('MEDIUM_UP')]: {
      alignItems: 'center',
    },
    [theme.mediaRule('LARGE_UP')]: {
      paddingLeft: '50px',
      paddingRight: '50px',
    },
    '& div': {
      width: '100%',
    },
    '& h1': {
      fontSize: '36px',
      lineHeight: 1.33,
      marginBottom: theme.spacing(2),
      fontWeight: theme.fontWeight.bold,
      color: 'white',
      maxWidth: '280px',
      [theme.mediaRule('MEDIUM_UP')]: {
        fontSize: '56px',
        lineHeight: 1.29,
        maxWidth: 'none',
      },
    },
    '& h3': {
      fontSize: '20px',
      lineHeight: 1.4,
      fontWeight: theme.fontWeight.medium,
      color: 'white',
      marginBottom: theme.spacing(3),
      [theme.mediaRule('MEDIUM_UP')]: {
        fontSize: '28px',
        lineHeight: 1.29,
        marginBottom: theme.spacing(6),
      },
    },
    '& button': {
      height: '42px !important',
    },
  },
}));

const BaseHero = (props) => {
  const {
    className,
    image,
    title,
    subTitle,
    action,
    locationId = '',
  } = props;
  const classes = useStyles();
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.MEDIUM_DOWN);
  const { handleOpenCloseModal } = useStoreContext();

  const defaultAction = <Button id={`buttonHero${locationId}`} variant="contained" style={{ width: isMobile ? '100%' : '250px' }} onClick={handleOpenCloseModal}>Get Started</Button>;

  return (
    <section id="heroSection" className={cx(classes.hero, className)} style={{ backgroundImage: `url(${image})` }}>
      <div className={classes.inner}>
        <div>
          <h1 id="heroTitle">{title}</h1>
          { subTitle && <h3 id="heroSubTitle">{subTitle}</h3> }
          { action || defaultAction }
        </div>
      </div>
    </section>
  );
};

BaseHero.propTypes = {
  className: PropTypes.string,
  image: PropTypes.string.isRequired,
  title: PropTypes.oneOf([PropTypes.string, PropTypes.node]).isRequired,
  subTitle: PropTypes.oneOf([PropTypes.string, PropTypes.node]),
  action: PropTypes.node,
  locationId: PropTypes.string,
};

export default BaseHero;
