import PropTypes from 'prop-types';
import React from 'react';
import { createUseStyles } from 'react-jss';
import cx from 'classnames';

const useStyles = createUseStyles(theme => ({
  button: {
    display: 'inline-block',
    marginBottom: 0,
    textAlign: 'center',
    whiteSpace: 'nowrap',
    touchAction: 'manipulation',
    cursor: 'pointer',
    userSelect: 'none',
    border: '1px solid transparent',
    borderRadius: theme.radius(),
    textTransform: 'uppercase',
    fontWeight: theme.fontWeight.bold,
    '&.fullWidth': {
      width: '100%',
    },

    // variants
    '&.text': {
      background: 'transparent',
      color: theme.palette.primary.main,
    },
    '&.contained': {
      background: theme.palette.primary.main,
      color: '#FFF',
      '&:hover, &:focus-visible, &:active': {
        background: theme.palette.primary.dark,
      },
    },
    '&.outlined': {
      background: 'transparent',
      border: `1.5px solid ${theme.palette.primary.main}`,
      color: theme.palette.primary.main,
      '&:hover, &:focus-visible, &:active': {
        color: theme.palette.primary.dark,
        borderColor: theme.palette.primary.dark,
      },
    },

    // sizes
    '&.small': {
      fontSize: '14px',
      height: '38px',
      padding: theme.spacing(0, 3),
      lineHeight: 1.29,
      letterSpacing: '.25px',
    },
    '&.medium': {
      fontSize: '1.4rem',
      padding: '0.85rem 1.3rem',
      fontWeight: theme.fontWeight.medium,
      lineHeight: '1.8rem',
    },
  },
}));

const Button = props => {
  const {
    variant = 'text',
    size = 'small',
    fullWidth,
    onClick,
    children,
    tabIndex,
    ...rest
  } = props;
  const classes = useStyles();

  return (
    <button
      type="button"
      tabIndex={tabIndex || 0}
      className={cx(classes.button, variant, size, { fullWidth })}
      onClick={e => {
        if (typeof onClick === 'function') {
          onClick(e);
        }
      }}
      {...rest}
    >
      {children}
    </button>
  );
};

Button.propTypes = {
  variant: PropTypes.oneOf(['text', 'outlined', 'contained']),
  size: PropTypes.oneOf(['small', 'medium', 'large']),
  fullWidth: PropTypes.bool,
  onClick: PropTypes.func,
  children: PropTypes.node,
  tabIndex: PropTypes.number,
  rest: PropTypes.object,
};

export default Button;
