import PropTypes from 'prop-types';
import React from 'react';
import { createUseStyles } from 'react-jss';

export const maxWidth = 390;

const useStyles = createUseStyles(theme => ({
  imageBox: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    textAlign: 'center',
    maxWidth: `${maxWidth}px`,
    '& > h1': {
      fontSize: '28px',
      lineHeight: 1.29,
      fontWeight: theme.fontWeight.medium,
      [theme.mediaRule('MEDIUM_UP')]: {
        fontSize: '32px',
        lineHeight: 1.25,
        marginBottom: theme.spacing(2),
      },
    },
  },
  image: {
    height: '173px',
    width: '263px',
    backgroundRepeat: 'no-repeat',
    backgroundSize: 'contain',
    backgroundPosition: 'center center',
    marginBottom: theme.spacing(3),
    [theme.mediaRule('MEDIUM_UP')]: {
      height: '200px',
      width: '204px',
      marginBottom: theme.spacing(4),
    },
    [theme.mediaRule('LARGE_UP')]: {
      height: '236px',
      width: '241px',
    },
  },
  message: {
    display: 'none',
    [theme.mediaRule('MEDIUM_UP')]: {
      fontSize: '20px',
      lineHeight: 1.4,
      display: 'block',
    },
  },
}));

const ImageBox = (props) => {
  const {
    id,
    image,
    title,
    message,
  } = props;
  const classes = useStyles();

  return (
    <div id={id} className={classes.imageBox}>
      <div
        id={`${id}Image`}
        className={classes.image}
        style={{ backgroundImage: `url(${image})` }}
        role="img"
      />
      <h1 id={`${id}Title`}>{ title }</h1>
      <div id={`${id}Message`} className={classes.message}>{ message }</div>
    </div>
  );
};

ImageBox.propTypes = {
  id: PropTypes.string.isRequired,
  image: PropTypes.string.isRequired,
  title: PropTypes.oneOf([PropTypes.string, PropTypes.node]).isRequired,
  message: PropTypes.oneOf([PropTypes.string, PropTypes.node]).isRequired,
};

export default ImageBox;
