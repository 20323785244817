import React from 'react';
import { createUseStyles, useTheme } from 'react-jss';
import { useMediaQuery } from '@react-hook/media-query';

import BaseHero from './BaseHero';

const heroImage = require('./assets/kpt-testimonials-hero.webp');
const heroMobileImage = require('./assets/kpt-testimonials-hero-mobile.webp');

const useStyles = createUseStyles(theme => ({
  testimonialsHero: {
    '& [class^=inner]': {
      [theme.mediaRule('MEDIUM_DOWN')]: {
        alignItems: 'flex-end',
      },
    },
    '& h1, & h3': {
      textShadow: '0 4px 4px rgba(0, 0, 0, .1)',
      [theme.mediaRule('MEDIUM_UP')]: {
        textShadow: '0 4px 4px rgba(0, 0, 0, .15)',
      },
    },
    '& h1': {
      maxWidth: '500px',
    },
    '& h3': {
      maxWidth: '525px',
    },
  },
}));

const TestimonialsHero = () => {
  const classes = useStyles();
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.MEDIUM_DOWN);

  return (
    <BaseHero
      className={classes.testimonialsHero}
      image={isMobile ? heroMobileImage : heroImage}
      title="Read Reviews From Our Clients"
      subTitle="Hear why parents love KidPass Tutors and recommend us to friends and family"
      locationId="Testimonials"
    />
  );
};

export default TestimonialsHero;
