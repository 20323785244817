import React from 'react';
import PropTypes from 'prop-types';
import { createUseStyles } from 'react-jss';

const useStyles = createUseStyles(theme => ({
  label: {
    fontSize: '1.6rem',
    margin: 0,
    fontWeight: theme.fontWeight.medium,
    display: 'inline-block',
    maxWidth: '100%',
    lineHeight: '2.2rem',
  },
}));

const Label = (props) => {
  const {
    htmlFor,
    ...rest
  } = props;
  const classes = useStyles();
  return (
    <label htmlFor={htmlFor} className={classes.label} {...rest}></label>
  );
};

Label.propTypes = {
  htmlFor: PropTypes.string,
  rest: PropTypes.object,
};
export default Label;
