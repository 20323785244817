import PropTypes from 'prop-types';
import React from 'react';
import isEmail from 'validator/lib/isEmail';

import { Input, Label, ErrorMessage } from '../../../form';
import Stack from '../../../layout/Stack';

export const validateEmail = (e) => {
  return typeof e === 'string' && isEmail(e);
};

export const validatePhone = (phoneNumber) => {
  // eslint-disable-next-line
  const phoneNumberPattern = /^[\+]?[(]?[0-9]{3}[)]?[-\s\.]?[0-9]{3}[-\s\.]?[0-9]{4,6}$/im;
  return phoneNumberPattern.test(phoneNumber);
};

export const validateZip = (zipCode) => {
  if (zipCode === '') {
    return true;
  }

  return /^\d{5}(-\d{4})?$/.test(zipCode);

};

export const FullName = (props) => {
  return (
    <>
      <ErrorMessage
        align="center"
        errorMessages={props.errorMessages}
      />
      <Stack direction="column" spacing={3} id="questionName" onChange={props.handleOnChange}>
        <div id="questionFirstName" style={{width:'100%'}}>
          <Label>First Name</Label>
          <Input id="answerFirstNameText" type="text" name="firstName" required style={{marginBottom: 0}} />
        </div>
        <div id="questionLastName" style={{width:'100%'}}>
          <Label>Last Name</Label>
          <Input id="answerLastName" type="text" name="lastName" required style={{ marginBottom: 0 }} />
        </div>
      </Stack>
    </>
  );
};

FullName.propTypes = {
  handleOnChange: PropTypes.func,
  errorMessages: PropTypes.array,
};

export const ZipCode = (props) => {
  return (
    <>
      <ErrorMessage
        align="center"
        errorMessages={props.errorMessages}
      />
      <div id="questionZip" onChange={props.handleOnChange}>
        <Label id="questionZip" htmlFor="zipCode">Zip Code</Label>
        <div id="answerZipText">
          <Input type="text" pattern="[0-9]+" maxLength="5" name="zipCode" required />
        </div>
      </div>
    </>
  );
};

ZipCode.propTypes = {
  handleOnChange: PropTypes.func,
  errorMessages: PropTypes.array,
};

export const Email = (props) => {
  return (
    <>
      <ErrorMessage
        align="center"
        errorMessages={props.errorMessages}
      />
      <div id="questionEmail" onChange={props.handleOnChange}>
        <Label id="questionEmail" htmlFor="email">Email</Label>
        <div id="answerEmailText">
          <Input type="email" name="email" required />
        </div>
      </div>
    </>
  );
};

Email.propTypes = {
  handleOnChange: PropTypes.func,
  errorMessages: PropTypes.array,
};

export const PhoneNumber = (props) => {
  return (
    <>
      <ErrorMessage
        align="center"
        errorMessages={props.errorMessages}
      />
      <div id="questionPhone" onChange={props.handleOnChange}>
        <Label htmlFor="phone">Phone Number</Label>
        <div id="answerPhoneText">
          <Input type="text" pattern="[0-9]+" name="phone" required />
        </div>
      </div>
    </>
  );
};

PhoneNumber.propTypes = {
  handleOnChange: PropTypes.func,
  errorMessages: PropTypes.array,
};
