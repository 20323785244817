import PropTypes from 'prop-types';
import React from 'react';
import { createUseStyles } from 'react-jss';

export const maxWidth = 320;

const useStyles = createUseStyles(theme => ({
  tutorBox: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    textAlign: 'center',
    maxWidth: `${maxWidth}px`,
    '& > h3': {
      fontSize: '22px',
      lineHeight: 1.27,
      fontWeight: theme.fontWeight.medium,
      marginBottom: theme.spacing(1),
      [theme.mediaRule('MEDIUM_UP')]: {
        fontSize: '24px',
        lineHeight: 1.33,
        marginBottom: theme.spacing(2),
      },
    },
  },
  image: {
    height: '120px',
    width: '120px',
    backgroundRepeat: 'no-repeat',
    backgroundSize: 'contain',
    backgroundPosition: 'center center',
    marginBottom: theme.spacing(3),
  },
  subjects: {
    extend: 'bio',
    fontStyle: 'italic',
    color: theme.palette.gray.dark,
    marginBottom: theme.spacing(1),
    [theme.mediaRule('MEDIUM_UP')]: {
      marginBottom: theme.spacing(2),
    },
  },
  bio: {
    fontSize: '18px',
    lineHeight: 1.33,
    [theme.mediaRule('MEDIUM_UP')]: {
      fontSize: '20px',
      lineHeight: 1.4,
    },
  },
}));

const TutorBox = (props) => {
  const {
    id,
    image,
    name,
    subjects,
    bio,
  } = props;
  const classes = useStyles();

  return (
    <div id={id} className={classes.tutorBox}>
      <div
        id={id && `${id}Image`}
        className={classes.image}
        style={{ backgroundImage: `url(${image})` }}
        role="img"
        aria-label={`Image of ${name}`}
      />
      <h3 id={id && `${id}Name`}>{ name }</h3>
      <div id={id && `${id}Subjects`} className={classes.subjects}>{ subjects }</div>
      <div id={id && `${id}Bio`} className={classes.bio}>{ bio }</div>
    </div>
  );
};

TutorBox.propTypes = {
  id: PropTypes.string,
  image: PropTypes.oneOf([PropTypes.string, PropTypes.node]).isRequired,
  name: PropTypes.oneOf([PropTypes.string, PropTypes.node]).isRequired,
  subjects: PropTypes.oneOf([PropTypes.string, PropTypes.node]).isRequired,
  bio: PropTypes.oneOf([PropTypes.string, PropTypes.node]).isRequired,
};

export default TutorBox;
