import PropTypes from 'prop-types';
import React from 'react';
import { createUseStyles } from 'react-jss';

import { CustomCheckbox, ErrorMessage } from '../../../form';
import Step from './BaseStep';
import Stack from '../../../layout/Stack';

const useStyles = createUseStyles(theme=>({
  otherInput: {
    width: '90%',
    borderRadius: '4px',
    border:' 1px solid #d7d7d7',
    backgroundColor: '#fff',
    padding:'0 10px',
    height: '38px',
    display: 'block',
    fontSize: '1.6rem',
    margin: '5px 5px 0 28px',
    color:theme.palette.text.main,
    [theme.mediaRule('MEDIUM_UP')]: {
      width: '100%',
      maxWidth: '620px',
    },
  },
}));

const TutoringTypeStep = (props) => {
  const classes = useStyles();

  return (
    <Step>
      <h4 id="questionType">What type(s) of tutoring are you interested in?</h4>
      <ErrorMessage
        align="center"
        errorMessages={props.errorMessages}
      />
      <Stack direction="column" spacing={2} onChange={props.handleOnChange}>
        <CustomCheckbox id="answerTypeReading" label="Reading" value="reading" name="tutoringType" />
        <CustomCheckbox id="answerTypePhonics" label="Phonics" value="phonics" name="tutoringType" />
        <CustomCheckbox id="answerTypeMath" label="Math" value="math" name="tutoringType" />
        <CustomCheckbox id="answerTypeKindergartenReadiness" label="Kindergarten Readiness" value="kindergarten-readiness" name="tutoringType" />
        <CustomCheckbox id="answerTypeForeignLanguages" label="Foreign Languages" value="foreign-languages" name="tutoringType" />
        <CustomCheckbox id="answerTypeScience" label="Science" value="science" name="tutoringType" />
        <CustomCheckbox id="answerTypeSocialStudies" label="Social Studies" value="social-studies" name="tutoringType" />
        <div style={{width: '100%'}}>
          <CustomCheckbox id="answerTypeOther" label="Other:" value="other" name="tutoringType"/>
          <input
            className={classes.otherInput}
            name="tutoringTypeOther"
            onClick={(e) => {
              e.stopPropagation();
            }}
            onChange={(e) => {
              e.stopPropagation();
              props.handleOnChange(e);
            }}
          />
        </div>
      </Stack>
    </Step>
  );
};

TutoringTypeStep.propTypes = {
  handleOnChange: PropTypes.func,
  errorMessages: PropTypes.array,
};

export default TutoringTypeStep;
