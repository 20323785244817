const mappings = {
  'prod': [
    'prod',
    'production',
  ],
  'stg': [
    'stg',
    'stage',
  ],
  'dev': [
    'dev',
    'development',
    'local',
  ],
};

const mapEnvToDD = (envName) => {
  let mappedEnv = 'dev';
  Object.keys(mappings).forEach((dataDogEnv) => {
    if (mappings[dataDogEnv].indexOf(envName) !== -1) {
      mappedEnv = dataDogEnv;
    }
  });
  return mappedEnv;
};

const getVersion = () => {
  if (typeof window === 'object') {
    return process.env.DD_VERSION;
  }

  if (process && process.env) {
    // eslint-disable-next-line global-require
    const packageInfo = require('../../../package.json');
    return packageInfo.version;
  }
  return undefined;
};

module.exports = {
  mapEnvToDD,
  getVersion,
};
