import PropTypes from 'prop-types';
import React from 'react';
import { createUseStyles } from 'react-jss';

import SideNavigation from './SideNavigation';
import { Expand, Fade } from '../../util';

const useStyles = createUseStyles(theme => ({
  drawer: {
    position: 'fixed',
    left: 0,
    top: 0,
    display: 'flex',
    flexDirection: 'column',
    width: '300px',
    maxWidth: '100vw',
    height: '100vh',
    background: 'white',
    zIndex: theme.layers.TOP,
    '& *': {
      lineHeight: 1.38,
    },
  },
  scrim: {
    position: 'fixed',
    inset: 0,
    background: 'rgba(0, 0, 0, .3)',
    zIndex: theme.layers[9],
  },
}));

const Drawer = props => {
  const { show, toggle } = props;
  const classes = useStyles();

  return (
    <>
      <Expand in={show} duration={300}>
        <div className={classes.drawer}>
          <SideNavigation toggle={toggle} />
        </div>
      </Expand>
      <Fade
        in={show}
        onEnter={() => {
          document.body.style.overflow = 'hidden';
        }}
        onExit={() => {
          document.body.style.overflow = 'initial';
        }}
      >
        <div
          role="none"
          className={classes.scrim}
          onClick={() => {
            toggle(false);
          }}
        />
      </Fade>
    </>
  );
};

Drawer.propTypes = {
  show: PropTypes.bool,
  toggle: PropTypes.func.isRequired,
};

export default Drawer;
