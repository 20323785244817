import PropTypes from 'prop-types';
import React from 'react';
import { createUseStyles } from 'react-jss';
import chunk from 'lodash/chunk';

import { Anchor } from '../util';

const useStyles = createUseStyles(theme => ({
  linkSection: {
    '& ul': {
      marginTop: theme.spacing(2),
      display: 'inline-flex',
      flexDirection: 'column',
      gap: theme.spacing(1),
      '&:not(:first-of-type)': {
        marginLeft: theme.spacing(10),
      },
    },
    '& a': {
      fontSize: '13px',
      lineHeight: 1.31,
      '&:hover, &:focus-visible': {
        opacity: .5,
      },
    },
  },
  title: {
    fontSize: '18px',
    fontWeight: theme.fontWeight.medium,
    lineHeight: 1.33,
  },
}));

const FooterLinkSection = (props) => {
  const { title, items, maxItemsPerColumn } = props;
  const classes = useStyles();

  const chunks = (isNaN(maxItemsPerColumn) || maxItemsPerColumn < 1) ? [Object.keys(items)] : chunk(Object.keys(items), maxItemsPerColumn);

  return (
    <section className={classes.linkSection}>
      <div className={classes.title}>{title}</div>
      {chunks.map((chunkItems, index) => (
        <ul key={index}>
          {chunkItems.map(key => (
            <li key={key}>
              <Anchor to={`${items[key]}`}>{key}</Anchor>
            </li>
          ))}
        </ul>
      ))}
    </section>
  );
};

FooterLinkSection.propTypes = {
  title: PropTypes.string.isRequired,
  items: PropTypes.arrayOf(PropTypes.object).isRequired,
  maxItemsPerColumn: PropTypes.number,
};

export default FooterLinkSection;
