import PropTypes from 'prop-types';
import React from 'react';
import { createUseStyles, useTheme } from 'react-jss';
import { useMediaQuery } from '@react-hook/media-query';

import Button from '../../Button';
import { useStoreContext } from '../../../StoreContext';

const platformImage = require('./assets/feature-platform.webp');
const programImage = require('./assets/feature-program.webp');
const listCheckCircle = require('./assets/list-check-circle.svg');

const useStyles = createUseStyles(theme => ({
  features: {
    width: '100%',
    '& button': {
      width: '250px',
    },
  },
  inner: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    textAlign: 'center',
    margin: '0 auto',
    padding: theme.spacing('80px', 4),
    maxWidth: `${theme.sectionMaxWidth}px`,
    [theme.mediaRule('MEDIUM_UP')]: {
      padding: theme.spacing('120px', 4),
    },
    [theme.mediaRule('LARGE_UP')]: {
      textAlign: 'left',
    },
    [theme.mediaRule('LARGE_UP')]: {
      paddingLeft: '50px',
      paddingRight: '50px',
    },
  },
  featureList: {
    display: 'flex',
    flexDirection: 'column',
    gap: theme.spacing(3),
    textAlign: 'left',
    marginBottom: theme.spacing(4),
    '& > li': {
      display: 'flex',
      alignItems: 'flex-start',
      fontSize: '20px',
      lineHeight: 1.4,
      [theme.mediaRule('MEDIUM_UP')]: {
        fontSize: '24px',
        lineHeight: 1.33,
      },
      '&:before': {
        content: '""',
        height: '24px',
        width: '24px',
        display: 'inline-block',
        flexShrink: 0,
        marginRight: '10px',
        marginTop: '5px',
        backgroundImage: `url(${listCheckCircle})`,
        backgroundRepeat: 'no-repeat',
        backgroundSize: 'contain',
        backgroundPosition: 'center center',
        [theme.mediaRule('MEDIUM_UP')]: {
          marginRight: '12px',
          marginTop: '4px',
        },
      },
    },
  },
  listImageWrapper: {
    display: 'flex',
    alignItems: 'center',
    width: '100%',
    flexDirection: 'column',
    [theme.mediaRule('LARGE_UP')]: {
      flexDirection: 'row',
      gap: theme.spacing(4),
      justifyContent: 'space-between',
    },
    '& h1': {
      fontSize: '28px',
      lineHeight: 1.29,
      fontWeight: theme.fontWeight.medium,
      marginBottom: theme.spacing(3),
      [theme.mediaRule('MEDIUM_UP')]: {
        fontSize: '32px',
        lineHeight: 1.25,
        marginBottom: theme.spacing(4),
      },
    },
    '&:first-of-type': {
      marginBottom: '100px',
      [theme.mediaRule('MEDIUM_UP')]: {
        marginBottom: '180px',
      },
    },
  },
  featureImage: {
    width: '100%',
    backgroundRepeat: 'no-repeat',
    backgroundSize: 'contain',
    backgroundPosition: 'center center',
    marginBottom: theme.spacing(5),
    [theme.mediaRule('LARGE_UP')]: {
      marginBottom: 0,
    },
  },
  platformImage: {
    extend: 'featureImage',
    maxWidth: '450px',
    height: '150px',
    backgroundImage: `url(${platformImage})`,
    [theme.mediaRule('LARGE_UP')]: {
      height: '265px',
    },
  },
  programImage: {
    extend: 'featureImage',
    maxWidth: '450px',
    height: '196px',
    backgroundImage: `url(${programImage})`,
    [theme.mediaRule('LARGE_UP')]: {
      height: '345px',
    },
  },
  textCol: {
    [theme.mediaRule('LARGE_UP')]: {
      textAlign: 'left',
      flex: 1,
      maxWidth: '620px',
      minWidth: '450px',
    },
  },
  imageCol: {
    [theme.mediaRule('LARGE_UP')]: {
      flex: 1,
      maxWidth: '620px',
      display: 'flex',
      justifyContent: 'center',
    },
  },
}));

const Features = (props) => {
  const {
    locationId = '',
  } = props;
  const classes = useStyles();
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.LARGE_DOWN);
  const { handleOpenCloseModal } = useStoreContext();

  return (
    <section id="featuresSection" className={classes.features}>
      <div className={classes.inner}>
        <div id="innovativePlatformWrapper" className={classes.listImageWrapper} style={{ flexDirection: !isMobile && 'row-reverse' }}>
          <div id="innovativePlatformImageColumn" className={classes.imageCol}>
            { isMobile && <h1 id="innovativePlatformMobileTitle">Innovative Learning Platform</h1> }
            <div id="innovativePlatformImage" className={classes.platformImage} />
          </div>
          <div id="innovativePlatformTextColumn" className={classes.textCol}>
            { !isMobile && <h1 id="innovativePlatformDesktopTitle">Innovative Learning Platform</h1> }
            <ul id="innovativePlatformList" className={classes.featureList}>
              <li id="innovativePlatformListItem1">Easy-to-use dashboard for flexible scheduling</li>
              <li id="innovativePlatformListItem2">Seamless communication tools for parents and tutors</li>
              <li id="innovativePlatformListItem3">Integrated solution for collaboration and engagement</li>
            </ul>
            <Button id={`buttonPlatform${locationId}`} variant="contained" fullWidth={isMobile} onClick={handleOpenCloseModal}>Get Started</Button>
          </div>
        </div>

        <div id="tutoringProgramWrapper" className={classes.listImageWrapper}>
          <div id="tutoringProgramImageColumn" className={classes.imageCol}>
            { isMobile && <h1 id="tutoringProgramMobileTitle">Award-Winning Tutoring Program</h1> }
            <div id="tutoringProgramImage" className={classes.programImage} />
          </div>
          <div id="tutoringProgramTextColumn" className={classes.textCol}>
            { !isMobile && <h1 id="tutoringProgramDesktopTitle">Award-Winning Tutoring Program</h1> }
            <ul id="tutoringProgramList" className={classes.featureList}>
              <li id="tutoringProgramListItem1">Experienced tutors for all subjects and grade levels</li>
              <li id="tutoringProgramListItem2">Personalized, adaptive learning plan for every child</li>
              <li id="tutoringProgramListItem3">Continuous feedback on your child’s progress</li>
            </ul>
            <Button id={`buttonProgram${locationId}`} variant="contained" fullWidth={isMobile} onClick={handleOpenCloseModal}>Get Started</Button>
          </div>
        </div>
      </div>
    </section>
  );
};

Features.propTypes = {
  locationId: PropTypes.string,
};

export default Features;
