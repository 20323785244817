import PropTypes from 'prop-types';
import React, { useState, useCallback } from 'react';
import { createUseStyles } from 'react-jss';

import navItems from '../navItems';
import SideNavItem from './SideNavItem';
import Button from '../../Button';
import Stack from '../../layout/Stack';
import { useContactPhone } from '../../../utils';
import { useStoreContext } from '../../../StoreContext';

const logoSVG = require('../assets/kidpass-tutors-logo-with-begin.svg');
const closeSVG = require('../assets/close-x.svg');
const backSVG = require('../assets/back-arrow.svg');

const useStyles = createUseStyles(theme => ({
  inner: {
    flex: 1,
    marginLeft: '50px', // action X icon width + 15px margin on each side
    overflow: 'auto',
  },
  sideNavigation: {
    marginBottom: theme.spacing(4),
    '& > ul': {
      margin: 0,
      padding: 0,
    },
  },
  titleBar: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    margin: theme.spacing(5, 0, '55px', 0),
  },
  titleContainer: {
    position: 'relative',
    '&:after': {
      content: '""',
      width: '40px',
      borderTop: `1px solid ${theme.palette.gray.border}`,
      position: 'absolute',
      bottom: '-35px',
    },
  },
  title: {
    width: '166px',
    color: theme.palette.gray.dark,
    fontSize: '16px',
    fontWeight: theme.fontWeight.medium,
  },
  logo: {
    width: '183px',
    height: '39px',
    backgroundImage: `url(${logoSVG})`,
    backgroundSize: 'contain',
    backgroundPosition: 'left center',
    backgroundRepeat: 'no-repeat',
  },
  actionContainer: {
    display: 'flex',
    justifyContent: 'center',
    margin: theme.spacing(0, 3),
  },
  closeIcon: {
    width: '20px',
    height: '20px',
    backgroundImage: `url(${closeSVG})`,
    backgroundSize: 'contain',
    backgroundPosition: 'center center',
    backgroundRepeat: 'no-repeat',
    cursor: 'pointer',
  },
  backIcon: {
    extend: 'closeIcon',
    backgroundImage: `url(${backSVG})`,
  },
}));

const Navigation = props => {
  const { toggle } = props;
  const classes = useStyles();
  const [itemTree, setItemTree] = useState({
    previous: null,
    current: navItems,
    selected: null,
  });
  const phoneNumber = useContactPhone();
  const { handleOpenCloseModal } = useStoreContext();

  const select = useCallback(selected => {
    setItemTree(prevItemTree => ({
      previous: prevItemTree,
      current: prevItemTree.current[selected],
      selected,
    }));
  }, []);

  const goBack = useCallback(() => {
    setItemTree(prevItemTree => prevItemTree.previous);
  }, []);

  return (
    <>
      <div className={classes.titleBar}>
        <div className={classes.actionContainer}>
          {itemTree.previous ? (
            <div tabIndex="0" role="button" className={classes.backIcon} aria-label="Go Back" title="Go Back" onKeyPress={e => e.key === 'Enter' && e.target.click()} onClick={goBack} />
          ) : (
            <div
              tabIndex="0"
              role="button"
              className={classes.closeIcon}
              aria-label="Close Menu"
              aria-expanded
              title="Close Menu"
              onKeyPress={e => e.key === 'Enter' && e.target.click()}
              onClick={() => {
                toggle(false);
              }}
            />
          )}
        </div>
        <div className={classes.titleContainer}>
          {itemTree.selected ? (
            <div className={classes.title}>{itemTree.selected}</div>
          ) : (
            <a tabIndex="-1" title="Discover online classes for kids" href="/">
              <div className={classes.logo} />
            </a>
          )}
        </div>
        <div className={classes.fillerContainer}></div>
      </div>
      <div className={classes.inner}>
        <nav className={classes.sideNavigation} role="navigation" aria-label="Main">
          <ul>
            {Object.keys(itemTree.current).map(key => (
              <SideNavItem
                key={key}
                label={key}
                to={itemTree.current[key]}
                select={select}
                closeMenu={() => {
                  toggle(false);
                }}
              />
            ))}
          </ul>
        </nav>
        {!itemTree.previous && (
          <Stack direction="column" spacing={3}>
            <a id="buttonLinkPhoneMobileNav" href={`tel:${phoneNumber}`}>
              <Button id="buttonPhoneMobileNav" variant="contained" tabIndex="-1" style={{width: 180}}>
                Call Now
              </Button>
            </a>
            <Button id="buttonFormMobileNav" variant="outlined" style={{width: 180}} onClick={()=>{ toggle(false); handleOpenCloseModal();}}>
              Get Started
            </Button>
          </Stack>
        )}
      </div>
    </>
  );
};

Navigation.propTypes = {
  toggle: PropTypes.func.isRequired,
};

export default Navigation;
