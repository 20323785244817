import PropTypes from 'prop-types';
import React, { useState, useCallback } from 'react';
import { createUseStyles } from 'react-jss';
import { CSSTransition } from 'react-transition-group';
import { Link } from 'react-router-dom';

import SubMenu from './SubMenu';

const rightChevronSVG = require('./assets/right-chevron.svg');
const rightChevronActiveSVG = require('./assets/right-chevron-active.svg');

const useStyles = createUseStyles(theme => ({
  subNavItem: {
    position: 'relative',
    width: '238px',
    padding: theme.spacing('6px', 2),
    color: theme.palette.gray.dark,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    textDecoration: 'none',
    '&:hover, &:focus-visible': {
      color: theme.palette.primary.main,
      '& > [class^=chevron]': {
        backgroundImage: `url(${rightChevronActiveSVG})`,
      },
    },
    '& .transition-enter, & .transition-enter-active': {
      opacity: 1,
    },
    '& .transition-exit, & .transition-exit-active': {
      opacity: 0,
    },
  },
  chevron: {
    display: 'inline-block',
    width: '8px',
    height: '12px',
    marginTop: '1px', // makes it look more centered
    backgroundImage: `url(${rightChevronSVG})`,
    backgroundSize: '100%',
    backgroundPosition: 'center center',
    backgroundRepeat: 'no-repeat',
  },
}));

const SubNavItem = props => {
  const { label, to } = props;
  const classes = useStyles();
  const [active, setActive] = useState(false);

  const _toggleActive = useCallback(value => {
    if (value) {
      setActive(true);
    } else {
      setActive(false);
    }
  }, []);

  switch (typeof to) {
  case 'string':
    return <Link className={classes.subNavItem} to={to}>{label}</Link>;
  case 'function':
    return (
      <li
        role="button"
        tabIndex="0"
        className={classes.subNavItem}
        onKeyPress={e => e.key === 'Enter' && e.target.click()}
        onClick={to}
      >
        {label}
      </li>
    );
  case 'object':
    return (
      <li
        role="button"
        tabIndex="0"
        className={classes.subNavItem}
        onMouseEnter={() => {
          _toggleActive(true);
        }}
        onMouseLeave={() => {
          _toggleActive(false);
        }}
        onFocus={() => {
          _toggleActive(true);
        }}
        onBlur={() => {
          _toggleActive(false);
        }}
      >
        {label}
        <div className={classes.chevron} />
        <CSSTransition
          in={active}
          timeout={100}
          classNames="transition"
          unmountOnExit
        >
          <SubMenu items={to} position="right" />
        </CSSTransition>
      </li>
    );
  default:
    return null;
  }
};

SubNavItem.propTypes = {
  label: PropTypes.string.isRequired,
  to: PropTypes.oneOf([PropTypes.func, PropTypes.object, PropTypes.string]).isRequired,
};

export default SubNavItem;
