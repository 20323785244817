import React, { useState, useEffect, useRef } from 'react';
import { createUseStyles, useTheme } from 'react-jss';
import { useMediaQuery } from '@react-hook/media-query';
import { BeginGlobalHeader, subscribe } from '@learnwithhomer/begin-component-library';
/* eslint-disable-next-line */
import '@learnwithhomer/begin-component-library/styles.css';

import { useStoreContext } from '../../StoreContext';
import Navigation from './Navigation';
import Button from '../Button';
import Stack from '../layout/Stack';
import Drawer from './Drawer';
import {
  useContactPhone,
  useQuery,
  pushToDataLayer,
} from '../../utils';

const logoSVG = require('./assets/kidpass-tutors-logo-with-begin.svg');
const hamburgerSVG = require('./assets/hamburger-menu-icon.svg');

const MAIN_NAV_HEIGHT = 73;

const useStyles = createUseStyles(theme => ({
  header: {
    position: 'fixed',
    top: 0,
    left: 0,
    width: '100%',
    zIndex: theme.layers[8],
  },
  mainNav: {
    height: `${MAIN_NAV_HEIGHT}px`,
    width: '100%',
    background: 'white',
    borderBottom: `1px solid ${theme.palette.gray.light}`,
    '& *': {
      lineHeight: 1.27,
    },
  },
  inner: {
    display: 'flex',
    flexDirection: 'row',
    width: '100%',
    height: '100%',
    alignItems: 'center',
    padding: theme.spacing(0, 4),
    maxWidth: `${theme.sectionMaxWidth - 60}px`,
    [theme.mediaRule('LARGE_UP')]: {
      padding: theme.spacing(0, 10),
      maxWidth: `${theme.sectionMaxWidth}px`,
    },
    margin: '0 auto',
  },
  hamburgerIcon: {
    width: '20px',
    height: '20px',
    marginRight: theme.spacing(2),
    backgroundImage: `url(${hamburgerSVG})`,
    backgroundSize: 'contain',
    backgroundPosition: 'center center',
    backgroundRepeat: 'no-repeat',
    cursor: 'pointer',
  },
  logoWrapper: {
    flex: 1,
    cursor: 'auto',
    '&[href]': {
      cursor: 'pointer',
    },
    [theme.mediaRule('XSMALL_UP')]: {
      flex: 'none',
    },
  },
  logo: {
    width: `calc(100% - ${theme.spacing(2)})`,
    height: '39px',
    backgroundImage: `url(${logoSVG})`,
    backgroundSize: 'contain',
    backgroundPosition: 'left center',
    backgroundRepeat: 'no-repeat',
    [theme.mediaRule('XSMALL_UP')]: {
      width: '183px',
      height: '39px',
    },
  },
  navWrapper: {
    display: 'none',
    [theme.mediaRule('XSMALL_UP')]: {
      display: 'block',
      flex: 1,
      height: '100%',
    },
  },
}));

const Header = () => {
  const classes = useStyles();
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.NAVIGATION_MOBILE);
  const phoneNumber = useContactPhone();
  const urlParams = useQuery();
  const [showDrawer, setShowDrawer] = useState(false);
  const headerRef = useRef(null);
  const {
    handleOpenCloseModal,
    setHeaderHeight,
    state: { currentPage, showModal: getStartedFormOpen },
  } = useStoreContext();

  useEffect(() => subscribe(pushToDataLayer, { replay: true }), []);

  useEffect(() => {
    if (!isMobile && showDrawer) {
      setShowDrawer(false);
    }
  }, [isMobile, showDrawer]);

  const _adjustHeaderHeight = () => {
    setHeaderHeight(headerRef?.current?.offsetHeight || MAIN_NAV_HEIGHT);
  };

  useEffect(() => {
    // dynamically monitor height of header as it changes and update the value in the store context for the main body offset

    if (headerRef?.current) {
      _adjustHeaderHeight();

      const observer = new MutationObserver(_adjustHeaderHeight);

      observer.observe(
        headerRef.current,
        {
          subtree: true,
          childList: true,
          attributes: true,
          characterData: true,
        }
      );
      window.addEventListener('resize', _adjustHeaderHeight);

      return () => {
        observer.disconnect();
        window.removeEventListener('resize', _adjustHeaderHeight);
      };
    }
  }, [headerRef]);

  const showBeginHeader = (
    (
      !['landing', 'thank-you'].includes(currentPage)
      || (currentPage === 'landing' && urlParams.get('utm_medium') === 'partner' && urlParams.get('utm_source') === 'MERIT' && urlParams.get('utm_campaign') === 'MERIT')
    )
    && !getStartedFormOpen
  );

  return (
    <>
      <header className={classes.header} ref={headerRef}>
        {showBeginHeader && (
          <div id="begin-global-nav-wrapper">
            <BeginGlobalHeader activeBrand="kidpasstutors" />
          </div>
        )}
        <div className={classes.mainNav}>
          <div className={classes.inner}>
            {isMobile && (
              <div
                tabIndex="0"
                role="button"
                className={classes.hamburgerIcon}
                aria-label="Open Menu"
                aria-expanded={showDrawer}
                title="Open Menu"
                onKeyPress={e => e.key === 'Enter' && e.target.click()}
                onClick={() => {
                  setShowDrawer(true);
                }}
              />
            )}
            <a tabIndex="-1" className={classes.logoWrapper} title="Discover online classes for kids" href={currentPage === 'landing' ? null : '/'}>
              <div className={classes.logo} />
            </a>
            <div className={classes.navWrapper}>{!isMobile && <Navigation />}</div>
            {isMobile ? (
              <a id="buttonLinkPhoneTopMobile" href={`tel:${phoneNumber}`}>
                <Button id="buttonPhoneTopMobile" variant="contained" tabIndex="-1">Call Now</Button>
              </a>
            ) : (
              <Stack alignItems="center" spacing={3} >
                <a id="buttonLinkPhoneTopDesktop" href={`tel:${phoneNumber}`}>
                  <Button id="buttonPhoneTopDesktop" variant="outlined" tabIndex="-1">Call: {phoneNumber}</Button>
                </a>
                <Button
                  id="buttonFormTopDesktop"
                  variant="contained"
                  style={{width: 150}}
                  onClick={handleOpenCloseModal}
                >
                  Get Started
                </Button>
              </Stack>
            )}
          </div>
        </div>
      </header>
      <Drawer show={showDrawer} toggle={setShowDrawer} />
    </>
  );
};

export default Header;
