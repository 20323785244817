import React, { useEffect } from 'react';

import { About, Testimonials as TestimonialsSection } from './sections';
import {
  ContactUs,
  FeaturedIn,
  Hero,
} from '../../components/sections';
import { useStoreContext } from '../../StoreContext';
import { useHelmet } from '../../utils';

const Testimonials = () => {
  const { dispatch } = useStoreContext();
  const helmet = useHelmet({
    title: 'Why Parents Love KidPass Tutors',
    description: 'Parents recommend KidPass Tutors to friends and family because we consistently help learners achieve their goal, whether that\'s catching up in a subject or staying ahead of the curve.',
    breadcrumbs: {'Testimonials': '/testimonials'},
  });

  useEffect(() => {
    dispatch({ type: 'set-current-page', payload: 'testimonials' });
  }, []);

  return (
    <div>
      {helmet}
      <Hero.Testimonials />
      <FeaturedIn />
      <About />
      <TestimonialsSection />
      <ContactUs locationId="Testimonials" style={{background: 'white'}} />
    </div>
  );
};

export default Testimonials;
