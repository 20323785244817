import React from 'react';
import { createUseStyles } from 'react-jss';

const loadingIcon = require('./assets/circle-notch-solid.svg');

const useStyles = createUseStyles(theme => ({
  loader: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    height: '180px',
  },
  inner: {
    backgroundColor: theme.palette.gray.light,
    borderRadius: '50%',
    width: '65px',
    height: '65px',
    '& img': {
      padding: '25%',
      opacity: .75,
      animation: '$spin 2s linear infinite',
    },
  },
  '@keyframes spin': {
    from: {
      transform: 'rotate(0deg)',
    },
    to: {
      transform: 'rotate(360deg)',
    },
  },
}));

const Loader = () => {
  const classes = useStyles();

  return (
    <div className={classes.loader} aria-busy>
      <div className={classes.inner}>
        <img src={loadingIcon} alt="Loading..." />
      </div>
    </div>
  );
};

export default Loader;
