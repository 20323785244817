import React from 'react';
import ReactDOM from 'react-dom/client';
import Modal from 'react-modal';

import App from './App';

const ddServiceName = 'tutors-site';
const ddEnvironments = [
  'stg',
  'stage',
  'prod',
  'production',
];

if (ddEnvironments.indexOf(process.env.NODE_ENV) !== -1 && process.env.DD_TRACE_ENABLED !== 'false') {
  // eslint-disable-next-line global-require
  require('./utils/datadog/rumApp')(ddServiceName);
  // eslint-disable-next-line global-require
  require('./utils/datadog/logger.browser')(ddServiceName);
}

const appEl = document.getElementById('app');

Modal.setAppElement(appEl);
const root = ReactDOM.createRoot(appEl);
root.render(<App />);
