import PropTypes from 'prop-types';
import React,
{
  useRef,
  useEffect,
  useState,
  useCallback,
  useMemo,
} from 'react';
import { createUseStyles, useTheme } from 'react-jss';
import cx from 'classnames';

import SubNavItem from './SubNavItem';

const useStyles = createUseStyles(theme => ({
  subMenu: {
    position: 'absolute',
    width: '240px',
    padding: theme.spacing(1, 0),
    background: 'white',
    border: `1px solid ${theme.palette.gray.border}`,
    borderRadius: theme.radius(),
    boxShadow: '0 1px 3px 0 rgba(0, 0, 0, .15)',
    '&.bottom': {
      bottom: theme.spacing(-1),
      transform: 'translateY(100%)',
    },
    '&.top': {
      top: theme.spacing(-1),
      transform: 'translateY(-100%)',
    },
    '&.right': {
      right: theme.spacing(-1),
      top: theme.spacing(-1),
      transform: 'translateX(100%)',
    },
    '&.left': {
      left: theme.spacing(-1),
      top: theme.spacing(-1),
      transform: 'translateX(-100%)',
    },
    '&.wrapped': {
      width: '480px',
      columnCount: 2,
    },
  },
}));

const SubMenu = props => {
  const { items, position = 'bottom' } = props;
  const classes = useStyles();
  const theme = useTheme();
  const menuRef = useRef(null);
  const [maxHeight, setMaxHeight] = useState(0);
  const [isWrapped, setIsWrapped] = useState(false);

  const _adjustMaxHeight = useCallback(() => {
    const targetMinHeight = 360;
    const menuElement = menuRef?.current;
    let calculatedMaxHeight;
    let itemHeight;

    if (menuElement) {
      const menuCoords = menuElement?.getBoundingClientRect();
      const itemCoords = menuElement?.querySelector('a')?.getBoundingClientRect();
      itemHeight = itemCoords ? itemCoords.bottom - itemCoords.top : 1;
      calculatedMaxHeight = window.innerHeight - menuCoords.y - (theme.baseSpace * 4); // fill maximum available viewport space from y coord minus margin
    }

    const itemCount = Object.keys(items).length;
    if (!calculatedMaxHeight || itemCount <= targetMinHeight / itemHeight) {
      // if less items than would fit in the targetMinHeight, we can ignore the "responsive" values
      setMaxHeight('none');
      setIsWrapped(false);
      return;
    }

    setMaxHeight(`${Math.max(targetMinHeight, calculatedMaxHeight)}px`);
    const availableHeight = (calculatedMaxHeight - (theme.baseSpace * 2) - 2); // calculatedMaxHeight minus padding and border
    setIsWrapped(itemCount > Math.floor(availableHeight / itemHeight)); // if more items than would fit in availableHeight, wrap (can only do 2 columns)
  }, []);

  useEffect(() => {
    _adjustMaxHeight();
    window.addEventListener('resize', _adjustMaxHeight);

    return () => {
      window.removeEventListener('resize', _adjustMaxHeight);
    };
  }, []);

  const memoizedItems = useMemo(() => {
    return Object.keys(items).map(key => (
      <SubNavItem key={key} label={key} to={items[key]} />
    ));
  }, []);

  return (
    <ul className={cx(classes.subMenu, position, {wrapped: isWrapped})} ref={menuRef} style={{maxHeight}}>
      {memoizedItems}
    </ul>
  );
};

SubMenu.propTypes = {
  items: PropTypes.array.isRequired,
  position: PropTypes.oneOf(['botton', 'top', 'right', 'left']),
};

export default SubMenu;
