import React from 'react';
import PropTypes from 'prop-types';
import { createUseStyles } from 'react-jss';

const useStyles = createUseStyles((theme)=>({
  radioContainer: {
    display: 'flex',
    alignItems: 'baseline',
  },
  radioLabel: {
    fontSize: '16px',
    lineHeight: '22px',
    marginBottom: 0,
    display: 'flex',
    cursor: 'pointer',
    alignItems: 'center',
  },
  radioInput: {
    width: '14px',
    height: '14px',
    outline: 'none',
    borderRadius: '50%',
    border: `1px solid ${theme.palette.gray.border}`,
    marginRight: '6px',
    marginTop: 0,
    cursor: 'pointer',
    '&:checked:before': {
      content: '""',
      display: 'block',
      width: '50%',
      height: '50%',
      margin: '25% auto',
      borderRadius: '50%',
      backgroundColor: theme.palette.primary.main,
    },
    '&:checked':{
      border: `1px solid ${theme.palette.primary.main}`,
    },
  },
}));

const CustomRadio = (props) => {

  const classes = useStyles();
  return (
    <div
      id={props.id}
      className={classes.radioContainer}
    >
      <label className= {classes.radioLabel}>
        <input className={classes.radioInput} type="radio" name={props.name} value={props.value} onChange={props.handleOnChange} style={{ marginRight: '8px' }} />
        { props.label }
      </label>
    </div>
  );
};

CustomRadio.propTypes = {
  id: PropTypes.string,
  value: PropTypes.string || PropTypes.number,
  label: PropTypes.string,
  name:  PropTypes.string,
  handleOnChange: PropTypes.func,
};

export default CustomRadio;
