import React from 'react';
import PropTypes from 'prop-types';
import { createUseStyles, useTheme } from 'react-jss';
import { useMediaQuery } from '@react-hook/media-query';

import { useStoreContext } from '../../StoreContext';
import Button from '../Button';
import Stack from '../layout/Stack';
import { useContactPhone } from '../../utils';

const useStyles = createUseStyles(theme => ({
  contactUs: {
    width: '100%',
    background: theme.palette.gray.lightest,
  },
  inner: {
    textAlign: 'center',
    padding: theme.spacing('80px', 4),
    maxWidth: `${theme.sectionMaxWidth}px`,
    margin: '0 auto',
    [theme.mediaRule('MEDIUM_UP')]: {
      padding: theme.spacing('120px', 4),
      '& button': {
        width: '200px',
      },
    },
    [theme.mediaRule('LARGE_UP')]: {
      paddingLeft: '50px',
      paddingRight: '50px',
    },
    '& h1': {
      fontSize: '28px',
      lineHeight: 1.29,
      fontWeight: theme.fontWeight.medium,
      marginBottom: theme.spacing(3),
      [theme.mediaRule('MEDIUM_UP')]: {
        fontSize: '32px',
        lineHeight: 1.25,
        marginBottom: theme.spacing(4),
      },
    },
  },
}));

const ContactUs = (props) => {
  const {
    style,
    locationId = '',
  } = props;
  const classes = useStyles();
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.MEDIUM_DOWN);
  const phoneNumber = useContactPhone();
  const { handleOpenCloseModal } = useStoreContext();

  return (
    <section id="contactSection" className={classes.contactUs} style={style}>
      <div className={classes.inner}>
        <h1 id="contactTitle">Contact Us Today for a Free Consultation</h1>
        <Stack justifyContent="center" spacing={2} direction={isMobile ? 'column' : 'row'}>
          <a
            id={`phoneLinkButtonBottom${locationId}${isMobile ? 'Mobile' : 'Desktop'}`}
            href={`tel:${phoneNumber}`}
            style={{ width: isMobile ? '100%' : 'auto' }}
          >
            <Button
              id={`phoneButtonBottom${locationId}${isMobile ? 'Mobile' : 'Desktop'}`}
              variant="contained"
              fullWidth={isMobile}
              tabIndex="-1"
            >
              Call: {phoneNumber}
            </Button>
          </a>
          <Button
            id={`phoneFormBottom${locationId}${isMobile ? 'Mobile' : 'Desktop'}`}
            variant="outlined"
            fullWidth={isMobile}
            onClick={handleOpenCloseModal}
          >
            Get Started
          </Button>
        </Stack>
      </div>
    </section>
  );
};

ContactUs.propTypes = {
  style: PropTypes.object,
  locationId: PropTypes.string,
};

export default ContactUs;
