import React from 'react';
import { ThemeProvider, jss } from 'react-jss';
import { Router } from 'react-router-dom';
import { createBrowserHistory } from 'history';
import { wrapHistory } from 'oaf-react-router';

import { StoreProvider } from './StoreContext';
import { reset, base, theme } from './styles';
import Main from './Main';

const history = createBrowserHistory();
wrapHistory(history);

jss.createStyleSheet(reset).attach();
jss.createStyleSheet(base).attach();

const App = () => {
  return (
    <StoreProvider>
      <ThemeProvider theme={theme}>
        <Router history={history}>
          <Main />
        </Router>
      </ThemeProvider>
    </StoreProvider>
  );
};

export default App;
