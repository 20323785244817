import PropTypes from 'prop-types';
import React, { useState, useEffect } from 'react';
import { createUseStyles } from 'react-jss';
import cx from 'classnames';
import { Link } from 'react-router-dom';

import SubMenu from './SubMenu';

const dropdownChevronSVG = require('./assets/dropdown-chevron.svg');
const dropdownChevronActiveSVG = require('./assets/dropdown-chevron-active.svg');
const dropdownChevronUpActiveSVG = require('./assets/dropdown-chevron-up-active.svg');

const useStyles = createUseStyles(theme => ({
  navItem: {
    display: 'flex',
    height: '100%',
    alignItems: 'center',
    color: theme.palette.gray.dark,
    fontSize: '15px',
    padding: theme.spacing(0, 2),
    cursor: 'pointer',
    fontWeight: theme.fontWeight.medium,
    textDecoration: 'none',
    '&.selected, &:hover, &:focus-visible': {
      color: theme.palette.primary.main,
    },
  },
  inner: {
    position: 'relative',
    display: 'flex',
    alignItems: 'center',
  },
  chevron: {
    display: 'inline-block',
    width: '10px',
    height: '8px',
    marginLeft: theme.spacing(1),
    backgroundImage: `url(${dropdownChevronSVG})`,
    backgroundSize: '100%',
    backgroundPosition: 'center center',
    backgroundRepeat: 'no-repeat',
    '.selected &': {
      backgroundImage: `url(${dropdownChevronUpActiveSVG})`,
    },
    '[class^="navItem"]:not(.selected):hover &, [class^="navItem"]:not(.selected):focus-visible &': {
      backgroundImage: `url(${dropdownChevronActiveSVG})`,
    },
  },
}));

const NavItem = props => {
  const { label, to } = props;
  const classes = useStyles();
  const [selected, setSelected] = useState(false);

  useEffect(() => {
    const clickHandler = () => {
      setSelected(false);
    };

    if (selected) {
      setTimeout(() => {
        window.addEventListener('click', clickHandler);
      }, 0);

      return () => {
        window.removeEventListener('click', clickHandler);
      };
    }
  }, [selected]);

  switch(typeof to) {
  case 'string':
    return <Link className={classes.navItem} to={to}>{label}</Link>;
  case 'function':
    return (
      <li
        role="button"
        tabIndex="0"
        className={classes.navItem}
        onKeyPress={e => e.key === 'Enter' && e.target.click()}
        onClick={to}
      >
        {label}
      </li>
    );
  case 'object':
    return (
      <li
        role="button"
        tabIndex="0"
        className={cx(classes.navItem, { selected })}
        onKeyPress={e => e.key === 'Enter' && e.target.click()}
        onClick={() => {
          setSelected(!selected);
        }}
      >
        <div className={classes.inner}>
          {label}
          <div className={classes.chevron} />
          {selected && <SubMenu items={to} />}
        </div>
      </li>
    );
  default:
    return null;
  }
};

NavItem.propTypes = {
  label: PropTypes.string.isRequired,
  to: PropTypes.oneOf([PropTypes.func, PropTypes.object, PropTypes.string]).isRequired,
};

export default NavItem;
