import PropTypes from 'prop-types';
import React from 'react';
import { createUseStyles } from 'react-jss';
import cx from 'classnames';

const prevIcon = require('./assets/prev-chevron.svg');
const nextIcon = require('./assets/next-chevron.svg');

const useStyles = createUseStyles(theme => ({
  carouselArrow: {
    position: 'absolute',
    top: '50%',
    left: 0,
    transform: 'translate(-50%, -50%)',
    width: '40px',
    height: '40px',
    padding: '12.5px 13.5px 12.5px 11.5px',
    boxShadow: '1px 3px 5px 0 rgba(0, 0, 0, .25)',
    background: 'white',
    borderRadius: '100%',
    zIndex: theme.layers[1],
    cursor: 'pointer',
    '&.next': {
      right: 0,
      left: 'unset',
      padding: '12.5px 11.5px 12.5px 13.5px',
      transform: 'translate(50%, -50%)',
    },
    [theme.mediaRule('MEDIUM_UP')]: {
      left: 0,
      width: '55px',
      height: '55px',
      padding: '16px 18px 16px 14px',
      '&.next': {
        right: 0,
        padding: '16px 14px 16px 18px',
      },
    },
  },
  icon: {
    width: '100%',
    height: '100%',
    backgroundRepeat: 'no-repeat',
    backgroundSize: 'contain',
    backgroundPosition: 'center center',
  },
}));

const TestimonialBox = (props) => {
  const {
    type,
    style,
    onClick,
  } = props;
  const classes = useStyles();

  return (
    <div
      role="button"
      tabIndex="0"
      className={cx(classes.carouselArrow, type)}
      onKeyPress={e => e.key === 'Enter' && e.target.click()}
      onClick={onClick}
      style={style}
    >
      <div
        className={classes.icon}
        style={{ backgroundImage: `url(${type === 'prev' ? prevIcon : nextIcon})` }}
        role="img"
        aria-label={type === 'prev' ? 'Previous arrow' : 'Next arrow'}
      />
    </div>
  );
};

TestimonialBox.propTypes = {
  type: PropTypes.oneOf(['prev', 'next']).isRequired,
  style: PropTypes.object,
  onClick: PropTypes.func,
};

export default TestimonialBox;
