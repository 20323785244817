import React from 'react';
import { createUseStyles } from 'react-jss';

import NavItem from './NavItem';
import navItems from './navItems';

const useStyles = createUseStyles(theme => ({
  navigation: {
    height: '100%',
    width: '100%',
    '& > ul': {
      display: 'flex',
      flexDirection: 'row',
      padding: theme.spacing(0, 4),
      gap: theme.spacing(1),
      height: '100%',
    },
  },
}));

const Navigation = () => {
  const classes = useStyles();

  return (
    <nav className={classes.navigation} role="navigation" aria-label="Main">
      <ul>
        {Object.keys(navItems).map(key => (
          <NavItem key={key} label={key} to={navItems[key]} />
        ))}
      </ul>
    </nav>
  );
};

export default Navigation;
