import React, { useEffect } from 'react';
import { createUseStyles } from 'react-jss';
import Helmet from 'react-helmet';
import Cookies from 'js-cookie';
import { useHistory } from 'react-router-dom';

import { useStoreContext } from '../StoreContext';
import { useContactPhone } from '../utils';

const useStyles = createUseStyles((theme) => ({
  thankyouContainer: {
    display: 'flex',
    padding: '0 20px',
    alignItems: 'center',
    maxWidth: '690px',
    justifyContent: 'flex-start',
    margin: '2rem auto 0 auto',
    [theme.mediaRule('MEDIUM_DOWN')]: {
      margin: '1.5rem auto 0 auto',
    },
    '& h2': {
      fontSize: '2.4rem',
      lineHeight: '32px',
      marginBottom: 0,
      textTransform: 'none',
      padding: '1rem 0',
      fontWeight: theme.fontWeight.medium,
      [theme.mediaRule('MEDIUM_UP')]: {
        fontSize: '3.2rem',
        lineHeight: '40px',
        paddingBottom: '15px',
        marginBottom: 0,
      },
    },
    '& p': {
      fontSize: '1.6rem',
      padding: 0,
      margin: '0 0 15px',
      lineHeight:'2.2rem',
      [theme.mediaRule('MEDIUM_UP')]: {
        fontSize: '1.8rem',
        lineHeight:'2.4rem',
      },
    },
    '& a': {
      color: theme.palette.primary.main,
      textDecoration: 'none',
      '&:hover': {
        textDecoration: 'underline',
      },
    },
    '& #linkThankYouPhoneDesktop': {
      [theme.mediaRule('MEDIUM_DOWN')]: {
        display: 'none',
      },
    },
    '& #linkThankYouPhoneMobile': {
      [theme.mediaRule('MEDIUM_UP')]: {
        display: 'none',
      },
    },
  },
  '@global': {
    'body footer': {
      display: 'none',
    },
    'body header':{
      '& [class^="navWrapper"], & #buttonPhoneTopDesktop, & #buttonFormTopDesktop, & [class^="hamburgerIcon"], & #buttonPhoneTopMobile':{
        display: 'none',
      },
    },
  },
}));

const Thankyou = () => {
  const classes = useStyles();
  const history = useHistory();
  const phoneNumber = useContactPhone();
  const { dispatch } = useStoreContext();

  useEffect(() => {
    if (!Cookies.get('get-started-form-complete')) {
      history.replace('/');
      return;
    }

    dispatch({ type: 'set-current-page', payload: 'thank-you' });
  }, []);

  return (
    <div className={classes.thankyouContainer}>
      <Helmet>
        <meta name="robots" content="noindex, follow" />
      </Helmet>
      <div>
        <h2>Thank you!</h2>
        <p>Schedule a time to speak with an educational consultant.&nbsp;<a href="https://onboard.kidpass.com/calendar/team/t/12" id="linkThankYouSchedule">Select a date & time</a>.</p>
        <p>Otherwise we&apos;ll call you soon to talk about your needs. If you are available for a consultation now, you can call us at&nbsp;<a href={`tel:${phoneNumber}`} id="linkThankYouPhoneDesktop">{phoneNumber}</a><a href={`tel:${phoneNumber}`} id="linkThankYouPhoneMobile">{phoneNumber}</a>.<br /></p>
      </div>
    </div>
  );
};

export default Thankyou;
