import PropTypes from 'prop-types';
import React from 'react';
import { createUseStyles } from 'react-jss';
import { Link } from 'react-router-dom';

const rightChevronSVG = require('../assets/right-chevron.svg');
const rightChevronActiveSVG = require('../assets/right-chevron-active.svg');

const useStyles = createUseStyles(theme => ({
  sideNavItem: {
    display: 'flex',
    alignItems: 'center',
    color: theme.palette.gray.dark,
    fontSize: '16px',
    padding: theme.spacing(2, 0),
    cursor: 'pointer',
    fontWeight: theme.fontWeight.medium,
    textDecoration: 'none',
    '&:hover, &:focus-visible': {
      color: theme.palette.primary.main,
      '& > [class^="chevron"]': {
        backgroundImage: `url(${rightChevronActiveSVG})`,
      },
    },
  },
  chevron: {
    display: 'inline-block',
    width: '8px',
    height: '12px',
    marginTop: '1px', // makes it look more centered
    marginLeft: '8px',
    backgroundImage: `url(${rightChevronSVG})`,
    backgroundSize: '100%',
    backgroundPosition: 'center center',
    backgroundRepeat: 'no-repeat',
  },
}));

const NavItem = props => {
  const { label, to, select, closeMenu } = props;
  const classes = useStyles();

  switch (typeof to) {
  case 'string':
    return <Link className={classes.sideNavItem} to={to} onClick={closeMenu}>{label}</Link>;
  case 'function':
    return (
      <li
        role="button"
        tabIndex="0"
        className={classes.sideNavItem}
        onKeyPress={e => e.key === 'Enter' && e.target.click()}
        onClick={() => {
          to();
          closeMenu();
        }}
      >
        {label}
      </li>
    );
  case 'object':
    return (
      <li
        role="button"
        tabIndex="0"
        className={classes.sideNavItem}
        onKeyPress={e => e.key === 'Enter' && e.target.click()}
        onClick={() => {
          select(label);
        }}
      >
        {label}
        <div className={classes.chevron} />
      </li>
    );
  default:
    return null;
  }
};

NavItem.propTypes = {
  label: PropTypes.string.isRequired,
  to: PropTypes.oneOf([PropTypes.func, PropTypes.object, PropTypes.string]).isRequired,
  select: PropTypes.func.isRequired,
  closeMenu: PropTypes.func.isRequired,
};

export default NavItem;
