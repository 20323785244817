const navItems = {
  Tutoring: {
    'By Subject': {
      English: {
        'All English': '/tutoring/english',
        Reading: '/tutoring/reading',
        Writing: '/tutoring/writing',
        Spelling: '/tutoring/spelling',
        Phonics: '/tutoring/phonics',
      },
      Math: {
        'All Math': '/tutoring/math',
        Algebra: '/tutoring/algebra',
        Geometry: '/tutoring/geometry',
        'Elementary Math': '/tutoring/elementary-math',
        'Middle School Math': '/tutoring/middle-school-math',
      },
      Science: {
        'All Science': '/tutoring/science',
        Biology: '/tutoring/biology',
        Chemistry: '/tutoring/chemistry',
        Physics: '/tutoring/physics',
      },
      Languages: {
        'All Languages': '/tutoring/languages',
        Spanish: '/tutoring/spanish',
        French: '/tutoring/french',
        German: '/tutoring/german',
        Italian: '/tutoring/italian',
        Japanese: '/tutoring/japanese',
        Korean: '/tutoring/korean',
        Latin: '/tutoring/latin',
        'Mandarin Chinese': '/tutoring/mandarin-chinese',
      },
      'Computer Science': {
        'All Computer Science': '/tutoring/computer-science',
        Coding: '/tutoring/coding',
        Java: '/tutoring/java',
        JavaScript: '/tutoring/javascript',
        Python: '/tutoring/python',
      },
      Humanities: {
        'All Humanities': '/tutoring/humanities',
        Geography: '/tutoring/geography',
        History: '/tutoring/history',
        Literature: '/tutoring/literature',
        'Social Studies': '/tutoring/social-studies',
      },
      Other: {
        'School Readiness': '/tutoring/school-readiness',
        'Social Emotional Learning': '/tutoring/social-emotional-learning',
        'Study Skills': '/tutoring/study-skills',
      },
    },
    'By Grade Level': {
      'Preschool': '/tutoring/preschool',
      'Pre-K': '/tutoring/pre-k',
      Kindergarten: '/tutoring/kindergarten',
      '1st Grade': '/tutoring/first-grade',
      '2nd Grade': '/tutoring/second-grade',
      '3rd Grade': '/tutoring/third-grade',
      '4th Grade': '/tutoring/fourth-grade',
      '5th Grade': '/tutoring/fifth-grade',
      '6th Grade': '/tutoring/sixth-grade',
      '7th Grade': '/tutoring/seventh-grade',
      '8th Grade': '/tutoring/eighth-grade',
      'High School': '/tutoring/high-school',
    },
    'Test Prep': {
      CogAT: '/tutoring/cogat',
      HSPT: '/tutoring/hspt',
      ISEE: '/tutoring/isee',
      TACHS: '/tutoring/tachs',
      SHSAT: '/tutoring/shsat',
    },
  },
  Classes: {
    'English Classes': {
      'All English': '/classes/english',
      Reading: '/classes/reading',
      Writing: '/classes/writing',
      Spelling: '/classes/spelling',
      Phonics: '/classes/phonics',
    },
    'Math Classes': {
      'All Math': '/classes/math',
      Algebra: '/classes/algebra',
      Geometry: '/classes/geometry',
      'Elementary Math': '/classes/elementary-math',
      'Middle School Math': '/classes/middle-school-math',
    },
    'Science Classes': {
      'All Science': '/classes/science',
      Biology: '/classes/biology',
      Chemistry: '/classes/chemistry',
      Physics: '/classes/physics',
    },
    'Languages Classes': {
      'All Languages': '/classes/languages',
      Spanish: '/classes/spanish',
      French: '/classes/french',
      German: '/classes/german',
      Italian: '/classes/italian',
      Japanese: '/classes/japanese',
      Korean: '/classes/korean',
      Latin: '/classes/latin',
      'Mandarin Chinese': '/classes/mandarin-chinese',
    },
    'Computer Science Classes': {
      'All Computer Science': '/classes/computer-science',
      Coding: '/classes/coding',
      Java: '/classes/java',
      JavaScript: '/classes/javascript',
      Python: '/classes/python',
    },
    'Humanities Classes': {
      'All Humanities': '/classes/humanities',
      Geography: '/classes/geography',
      History: '/classes/history',
      Literature: '/classes/literature',
      'Social Studies': '/classes/social-studies',
    },
    'Test Prep': {
      CogAT: '/classes/cogat',
      HSPT: '/classes/hspt',
      ISEE: '/classes/isee',
      TACHS: '/classes/tachs',
      SHSAT: '/classes/shsat',
    },
    'Other Classes': {
      'School Readiness': '/classes/school-readiness',
      'Social Emotional Learning': '/classes/social-emotional-learning',
      'Study Skills': '/classes/study-skills',
    },
  },
  'About Us': {
    'Testimonials': '/testimonials',
    'Team': '/team',
  },
};

export default navItems;
