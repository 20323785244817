const shannonImage = require('./assets/tutor-shannon.webp');
const abbyImage = require('./assets/tutor-abby.webp');
const jasonImage = require('./assets/tutor-jason.webp');
const amandaImage = require('./assets/tutor-amanda.webp');
const kaitlynImage = require('./assets/tutor-kaitlyn.webp');
const kathleenImage = require('./assets/tutor-kathleen.webp');

const testimonialItems = [
  {
    image: shannonImage,
    name: 'Shannon',
    subjects: 'Math, reading, science',
    bio: 'K-12 teacher with 10+ years of teaching experience and a Masters in Elementary Education',
  },
  {
    image: abbyImage,
    name: 'Abby',
    subjects: 'Math, reading, Spanish',
    bio: 'Elementary school teacher with 5+ years of experience, including online learning',
  },
  {
    image: jasonImage,
    name: 'Jason',
    subjects: 'Reading, math, French, test prep',
    bio: 'K-12 teacher and education researcher with a Master’s degree from Columbia',
  },
  {
    image: amandaImage,
    name: 'Amanda',
    subjects: 'English, reading, phonics',
    bio: 'K-5 teacher with 20+ years of experience, including pre-K and homeschooling',
  },
  {
    image: kaitlynImage,
    name: 'Kaitlyn',
    subjects: 'Math, reading, physics',
    bio: 'K-12 teacher with a Bachelor’s degree in Biomedical Engineering from a top university',
  },
  {
    image: kathleenImage,
    name: 'Kathleen',
    subjects: 'Reading, English, math',
    bio: 'Pre-K to 8th grade teacher with 5+ years of experience and a Master’s degree in Curriculum and Instruction',
  },
];

export default testimonialItems;
