import PropTypes from 'prop-types';
import React from 'react';
import { createUseStyles, useTheme } from 'react-jss';
import { useMediaQuery } from '@react-hook/media-query';

import footerItems from './footerItems';
import FooterLinkSection from './FooterLinkSection';
import { Anchor } from '../util';
import { useStoreContext } from '../../StoreContext';

const useStyles = createUseStyles(theme => ({
  footer: {
    flex: 1,
    overflow: 'initial',
    background: '#5a5a5a',
    color: 'white',
    textAlign: 'center',
    padding: theme.spacing(8, 4),
    [theme.mediaRule('MEDIUM_UP')]: {
      padding: theme.spacing(10, 4),
    },
    '& a': {
      color: 'inherit',
      textDecoration: 'none',
    },
  },
  inner: {
    width: '100%',
    maxWidth: `${theme.sectionMaxWidth}px`,
    margin: '0 auto',
  },
  linkWrapper: {
    display: 'flex',
    flexDirection: 'column',
    gap: theme.spacing(6),
    textAlign: 'left',
    marginBottom: theme.spacing(8),
    '@media only screen and (min-width: 620px)': {
      display: 'grid',
      gridTemplateColumns: 'repeat(2, max-content)',
      columnGap: theme.spacing(10),
    },
    [theme.mediaRule('NAVIGATION_DESKTOP')]: {
      display: 'flex',
      gap: 0,
      flexDirection: 'row',
      justifyContent: 'space-between',
      marginBottom: theme.spacing(10),
      columnGap: 0,
    },
  },
  linkWrapperBasic: {
    fontSize: '13px',
    lineHeight: '17px',
    marginTop: theme.spacing(2),
  },
  copyright: {
    fontSize: '14px',
    lineHeight: 1.36,
  },
}));

const Footer = (props) => {
  const { state: { currentPage } } = useStoreContext();
  const {
    basic = currentPage === 'landing', // default to basic on Landing page
  } = props;
  const classes = useStyles();
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.NAVIGATION_MOBILE);

  return (
    <footer className={classes.footer}>
      <div className={classes.inner}>
        { !basic && (
          <div className={classes.linkWrapper}>
            {Object.keys(footerItems).map(key => (
              <FooterLinkSection
                key={key}
                title={key}
                items={footerItems[key]}
                maxItemsPerColumn={key === 'Top Locations' && isMobile ? 14 : 9}
              />
            ))}
          </div>
        )}
        <span className={classes.copyright}>&copy; {new Date().getFullYear()} KIDPASS, INC.</span>
        { basic && (
          <div className={classes.linkWrapperBasic}>
            <Anchor to="/team">About</Anchor>&nbsp;|&nbsp;
            <Anchor to="https://kidpass.com/terms">Terms of Service</Anchor>&nbsp;|&nbsp;
            <Anchor to="https://kidpass.com/privacy">Privacy Policy</Anchor>&nbsp;|&nbsp;
            <Anchor to="https://homerlearning-kidpass.kustomer.help/contact/contact-us-B11fIiTrK">Contact Us</Anchor>
          </div>
        )}
      </div>
    </footer>
  );
};

Footer.propTypes = {
  basic: PropTypes.bool,
};

export default Footer;
