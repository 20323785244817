import PropTypes from 'prop-types';
import React from 'react';
import { createUseStyles } from 'react-jss';
import { CSSTransition } from 'react-transition-group';
import cx from 'classnames';

const useStyles = createUseStyles({
  expand: {
    '&.horizontal .transition-enter': {
      transform: 'translate3d(-100%, 0, 0)',
      pointerEvents: 'none',
    },
    '&.horizontal .transition-enter-active': {
      transform: 'translate3d(0, 0, 0)',
      pointerEvents: 'none',
    },
    '&.horizontal .transition-exit': {
      transform: 'translate3d(0, 0, 0)',
      pointerEvents: 'none',
    },
    '&.horizontal .transition-exit-active': {
      transform: 'translate3d(-100%, 0, 0)',
      pointerEvents: 'none',
    },
  },
});

const Expand = props => {
  const { duration = 500, direction = 'horizontal', children, onEnter, onExit } = props;
  const classes = useStyles();

  return (
    <div className={cx(classes.expand, direction)}>
      <CSSTransition
        in={props.in}
        timeout={duration}
        onEnter={node => {
          const transition = `transform ${duration}ms ease-in`;

          node.style.webkitTransition = transition;
          node.style.transition = transition;

          if (typeof onEnter === 'function') {
            onEnter(node);
          }
        }}
        onExit={node => {
          const transition = `transform ${duration}ms ease-out`;

          node.style.webkitTransition = transition;
          node.style.transition = transition;

          if (typeof onExit === 'function') {
            onExit(node);
          }
        }}
        classNames="transition"
        unmountOnExit
      >
        {children}
      </CSSTransition>
    </div>
  );
};

Expand.propTypes = {
  in: PropTypes.bool,
  duration: PropTypes.number,
  direction: PropTypes.oneOf(['horizontal', 'vertical']),
  children: PropTypes.node,
  onEnter: PropTypes.func,
  onExit: PropTypes.func,
};

export default Expand;
