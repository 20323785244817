import theme from './theme';

const CircularLight = require('./fonts/CircularXXSub-LightSubset.woff');
const CircularLight2 = require('./fonts/CircularXXSub-LightSubset.woff2');
const CircularMedium = require('./fonts/CircularXXSub-MediumSubset.woff');
const CircularMedium2 = require('./fonts/CircularXXSub-MediumSubset.woff2');
const CircularBold = require('./fonts/CircularXXSub-BoldSubset.woff');
const CircularBold2 = require('./fonts/CircularXXSub-BoldSubset.woff2');
const CircularLightItalic = require('./fonts/CircularXXSub-LightItalicSubset.woff');
const CircularLightItalic2 = require('./fonts/CircularXXSub-LightItalicSubset.woff2');
const CircularMediumItalic = require('./fonts/CircularXXSub-MediumItalicSubset.woff');
const CircularMediumItalic2 = require('./fonts/CircularXXSub-MediumItalicSubset.woff2');
const CircularBoldItalic = require('./fonts/CircularXXSub-BoldItalicSubset.woff');
const CircularBoldItalic2 = require('./fonts/CircularXXSub-BoldItalicSubset.woff2');

const base = {
  '@font-face': [
    {
      fontFamily: 'Circular',
      src: [`url("${CircularLight}") format("woff")`, `url("${CircularLight2}") format("woff2")`],
      fontWeight: theme.fontWeight.normal,
      fontDisplay: 'swap',
    },
    {
      fontFamily: 'Circular',
      src: [`url("${CircularMedium}") format("woff")`, `url("${CircularMedium2}") format("woff2")`],
      fontWeight: theme.fontWeight.medium,
      fontDisplay: 'swap',
    },
    {
      fontFamily: 'Circular',
      src: [`url("${CircularBold}") format("woff")`, `url("${CircularBold2}") format("woff2")`],
      fontWeight: theme.fontWeight.bold,
      fontDisplay: 'swap',
    },
    {
      fontFamily: 'Circular',
      src: [`url("${CircularLightItalic}") format("woff")`, `url("${CircularLightItalic2}") format("woff2")`],
      fontWeight: theme.fontWeight.normal,
      fontStyle: 'italic',
      fontDisplay: 'swap',
    },
    {
      fontFamily: 'Circular',
      src: [`url("${CircularMediumItalic}") format("woff")`, `url("${CircularMediumItalic2}") format("woff2")`],
      fontWeight: theme.fontWeight.medium,
      fontStyle: 'italic',
      fontDisplay: 'swap',
    },
    {
      fontFamily: 'Circular',
      src: [`url("${CircularBoldItalic}") format("woff")`, `url("${CircularBoldItalic2}") format("woff2")`],
      fontWeight: theme.fontWeight.bold,
      fontStyle: 'italic',
      fontDisplay: 'swap',
    },
  ],
  '@global': {
    body: {
      fontFamily: 'Circular, sans-serif',
      fontWeight: theme.fontWeight.normal,
      color: theme.palette.text.main,
    },
    'html, body': {
      width: '100%',
      height: '100%',
      fontSize: '10px',
      '&.ReactModal__Body--open': {
        overflow: 'hidden',
      },
      '& .ReactModal__Overlay': {
        zIndex: theme.layers[5],
      },
    },
    '[id=app]': {
      width: '100%',
      height: '100%',
      '& > div': {
        width: '100%',
        height: '100%',
        display: 'flex',
        flexDirection: 'column',
      },
    },
    '*:focus-visible': {
      outline: 'none !important',
      boxShadow: '0 0 0 2px rgba(0, 180, 240, .5)',
    },
  },
};

export default base;
