import PropTypes from 'prop-types';
import React from 'react';
import { createUseStyles } from 'react-jss';
import { CSSTransition } from 'react-transition-group';

const useStyles = createUseStyles({
  fade: {
    '& .transition-enter': {
      opacity: 0,
      pointerEvents: 'none',
    },
    '& .transition-enter-active': {
      opacity: 1,
      pointerEvents: 'none',
    },
    '& .transition-exit': {
      opacity: 1,
      pointerEvents: 'none',
    },
    '& .transition-exit-active': {
      opacity: 0,
      pointerEvents: 'none',
    },
  },
});

const Fade = props => {
  const { duration = 500, children, onEnter, onExit } = props;
  const classes = useStyles();

  return (
    <div className={classes.fade}>
      <CSSTransition
        in={props.in}
        timeout={duration}
        onEnter={node => {
          const transition = `opacity ${duration}ms ease-in`;

          node.style.webkitTransition = transition;
          node.style.transition = transition;

          if (typeof onEnter === 'function') {
            onEnter(node);
          }
        }}
        onExit={node => {
          const transition = `opacity ${duration}ms ease-out`;

          node.style.webkitTransition = transition;
          node.style.transition = transition;

          if (typeof onExit === 'function') {
            onExit(node);
          }
        }}
        classNames="transition"
        unmountOnExit
      >
        {children}
      </CSSTransition>
    </div>
  );
};

Fade.propTypes = {
  in: PropTypes.bool,
  duration: PropTypes.number,
  children: PropTypes.node,
  onEnter: PropTypes.func,
  onExit: PropTypes.func,
};

export default Fade;
