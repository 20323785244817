import React from 'react';
import { createUseStyles } from 'react-jss';

import tutorItems from '../tutors/tutorItems';
import TutorBox from '../tutors/TutorBox';

const useStyles = createUseStyles(theme => ({
  tutors: {
    width: '100%',
    background: '#fdf8f1',
  },
  inner: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    textAlign: 'center',
    margin: '0 auto',
    padding: theme.spacing('80px', 4),
    maxWidth: `${theme.sectionMaxWidth}px`,
    [theme.mediaRule('MEDIUM_UP')]: {
      padding: theme.spacing('120px', 4),
    },
    [theme.mediaRule('LARGE_UP')]: {
      paddingLeft: '50px',
      paddingRight: '50px',
    },
    '& > h1': {
      fontSize: '28px',
      lineHeight: 1.29,
      fontWeight: theme.fontWeight.medium,
      marginBottom: theme.spacing(2),
      [theme.mediaRule('MEDIUM_UP')]: {
        fontSize: '32px',
        lineHeight: 1.25,
        marginBottom: theme.spacing(3),
      },
    },
  },
  message: {
    fontSize: '20px',
    lineHeight: 1.4,
    marginBottom: '40px',
    maxWidth: '900px',
    [theme.mediaRule('MEDIUM_UP')]: {
      fontSize: '24px',
      lineHeight: 1.33,
      marginBottom: '65px',
    },
  },
  tutorBoxWrapper: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    gap: '40px',
    width: '100%',
    [theme.mediaRule('LARGE_UP')]: {
      flexDirection: 'row',
      alignItems: 'flex-start',
      justifyContent: 'space-between',
      gap: '50px',
    },
  },
}));

const Tutors = () => {
  const classes = useStyles();

  return (
    <section id="tutorsSection" className={classes.tutors}>
      <div className={classes.inner}>
        <h1 id="tutorsTitle">Learn From Experienced and Caring Educators</h1>
        <div id="tutorsMessage" className={classes.message}>
          We find the perfect tutor for your child&apos;s unique learning style and to motivate them to succeed in and out of the classroom.
          We choose only the most qualified educators through rigorous certifications screening, interviews, and custom-matching.
        </div>

        <div id="tutorsWrapper" className={classes.tutorBoxWrapper}>
          { tutorItems.slice(0, 3).map((item, index) => (
            <TutorBox id={`tutorsBox${index + 1}`} key={item.name} {...item} />
          ))}
        </div>
      </div>
    </section>
  );
};

export default Tutors;
