import PropTypes from 'prop-types';
import React from 'react';
import { createUseStyles } from 'react-jss';

const useStyles = createUseStyles((theme) => ({
  wrapperComponent: {
    marginBottom: theme.spacing(4),
    '& h4': {
      marginBottom: '1.5rem',
      marginTop: 0,
      lineHeight: '2.8rem',
      fontSize: '2.2rem',
      fontWeight: theme.fontWeight.medium,
      [theme.mediaRule('MEDIUM_UP')]: {
        lineHeight: '3.2rem',
        fontSize: '2.4rem',
      },
    },
    '& .spacing': {
      '& > *': {
        marginBottom:'1rem',
      },
    },
  },
}));

const Step = ({children}) => {
  const classes = useStyles();

  return (
    <div className={classes.wrapperComponent}>
      {children}
    </div>
  );
};

Step.propTypes = {
  children: PropTypes.node,
};

export default Step;
