import React, { useEffect } from 'react';
import Cookies from 'js-cookie';

import HelmetDefaults from './HelmetDefaults';
import Header from './components/Header';
import Footer from './components/Footer';
import Routes from './Routes';
import GetStartedForm from './components/modals/GetStartedForm';
import { useStoreContext } from './StoreContext';

const Main = () => {
  const { state: { headerHeight } } = useStoreContext();

  useEffect(() => {
    const urlQuery = new URLSearchParams(window.location.search);
    const gclid = urlQuery.get('gclid');
    if (gclid) {
      Cookies.set('GCLID', gclid, { expires: 90 });
    }
  }, []);

  return (
    <div>
      <HelmetDefaults />
      <Header />
      <main style={{ paddingTop: `${headerHeight}px` }}>
        <Routes />
        <GetStartedForm />
      </main>
      <Footer />
    </div>
  );
};

export default Main;
