import Base from './BaseHero';
import Home from './HomeHero';
import Team from './TeamHero';
import Template from './TemplateHero';
import Testimonials from './TestimonialsHero';

export default {
  Base,
  Home,
  Team,
  Template,
  Testimonials,
};
