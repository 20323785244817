import React from 'react';
import { createUseStyles, useTheme } from 'react-jss';
import { useMediaQuery } from '@react-hook/media-query';

import BaseHero from './BaseHero';

const heroImage = require('./assets/kpt-team-hero.webp');
const heroMobileImage = require('./assets/kpt-team-hero-mobile.webp');

const useStyles = createUseStyles(theme => ({
  teamHero: {
    backgroundPosition: 'right bottom',
    [theme.mediaRule('MEDIUM_UP')]: {
      backgroundPosition: 'right center',
    },
    '& h1': {
      maxWidth: '272px',
      color: theme.palette.text.main,
      [theme.mediaRule('MEDIUM_UP')]: {
        maxWidth: 'none',
      },
    },
    '& h3': {
      maxWidth: '600px',
      color: theme.palette.text.main,
    },
  },
}));

const TeamHero = () => {
  const classes = useStyles();
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.MEDIUM_DOWN);

  return (
    <BaseHero
      className={classes.teamHero}
      image={isMobile ? heroMobileImage : heroImage}
      title="Our Mission and Team"
      subTitle="Our team of impact-driven leaders and passionate learning experts are on a mission to help all students unlock their potential"
      locationId="Team"
    />
  );
};

export default TeamHero;
