import React, { useEffect } from 'react';
import { createUseStyles } from 'react-jss';

import {
  ContactUs,
  FeaturedIn,
  Features,
  Hero,
  InspireAndAdvance,
  SatisfactionGuarantee,
  Subjects,
  Testimonials,
  TrustPilot,
  Tutors,
} from '../components/sections';
import { useStoreContext } from '../StoreContext';
import { useHelmet } from '../utils';

const useStyles = createUseStyles({
  home: {
    '& section[class^="contactUs"]': {
      background: 'white',
    },
  },
});

const Home = () => {
  const classes = useStyles();
  const { dispatch } = useStoreContext();
  const helmet = useHelmet({
    title: 'KidPass Tutors: Top-Rated Tutoring for Young Learners',
    description: 'Choose from the best tutors for any subject. Personalized, private tutoring that guarantees results.',
  });

  useEffect(() => {
    dispatch({ type: 'set-current-page', payload: 'home' });
  }, []);

  return (
    <div className={classes.home}>
      {helmet}
      <Hero.Home />
      <FeaturedIn />
      <TrustPilot />
      <InspireAndAdvance />
      <Testimonials />
      <Subjects />
      <Tutors />
      <Features />
      <SatisfactionGuarantee />
      <ContactUs />
    </div>
  );
};

export default Home;
