import PropTypes from 'prop-types';
import React from 'react';
import { createUseStyles, useTheme } from 'react-jss';
import { useMediaQuery } from '@react-hook/media-query';

import BaseHero from './BaseHero';

const heroImage = require('./assets/kpt-site-hero.webp');
const heroMobileImage = require('./assets/kpt-site-hero-mobile.webp');

const useStyles = createUseStyles(theme => ({
  homeHero: {
    backgroundPosition: 'right bottom',
    [theme.mediaRule('MEDIUM_UP')]: {
      backgroundPosition: 'right center',
    },
    '& h3':{
      maxWidth: '750px',
    },
  },
}));

const HomeHero = (props) => {
  const { title } = props;
  const classes = useStyles();
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.MEDIUM_DOWN);

  return (
    <BaseHero
      className={classes.homeHero}
      image={isMobile ? heroMobileImage : heroImage}
      title={title || 'Tutoring For Young Learners'}
      subTitle="Trusted by thousands of parents. Reach out for a free consultation to see how we can help your child succeed!"
    />
  );
};

HomeHero.propTypes = {
  title: PropTypes.string,
};

export default HomeHero;
