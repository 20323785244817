import PropTypes from 'prop-types';
import React from 'react';
import { createUseStyles, useTheme } from 'react-jss';
import { useMediaQuery } from '@react-hook/media-query';

import Button from '../../Button';
import ImageBox, { maxWidth as imageBoxMaxWidth } from './ImageBox';
import { useStoreContext } from '../../../StoreContext';

const personalizedInstructionImage = require('./assets/personalized-instruction.webp');
const convenientSchedulingImage = require('./assets/convenient-scheduling.webp');
const provenResultsImage = require('./assets/proven-results.webp');

const useStyles = createUseStyles(theme => ({
  inspireAndAdvance: {
    width: '100%',
    '& button': {
      [theme.mediaRule('MEDIUM_UP')]: {
        width: '250px',
      },
    },
  },
  inspireWrapper: {
    width: '100%',
    background: theme.palette.yellow.lightest,
    padding: theme.spacing('40px', 4, '80px', 4),
    [theme.mediaRule('MEDIUM_UP')]: {
      padding: theme.spacing('60px', 4, '120px', 4),
    },
    [theme.mediaRule('LARGE_UP')]: {
      paddingLeft: '50px',
      paddingRight: '50px',
    },
  },
  advanceWrapper: {
    width: '100%',
    padding: theme.spacing('80px', 4),
    [theme.mediaRule('MEDIUM_UP')]: {
      padding: theme.spacing('120px', 4),
    },
    [theme.mediaRule('LARGE_UP')]: {
      paddingLeft: '50px',
      paddingRight: '50px',
    },
    '& [class^="inner"] > h1': {
      marginBottom: theme.spacing(4),
      [theme.mediaRule('MEDIUM_UP')]: {
        marginBottom: theme.spacing(6),
      },
    },
    '& button': {
      maxWidth: `${imageBoxMaxWidth}px`,
    },
  },
  inner: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    textAlign: 'center',
    margin: '0 auto',
    maxWidth: `${theme.sectionMaxWidth}px`,
    '& > h1': {
      fontSize: '28px',
      lineHeight: 1.29,
      fontWeight: theme.fontWeight.medium,
      marginBottom: theme.spacing(2),
      [theme.mediaRule('MEDIUM_UP')]: {
        fontSize: '32px',
        lineHeight: 1.25,
        marginBottom: theme.spacing(3),
      },
    },
  },
  message: {
    fontSize: '20px',
    lineHeight: 1.4,
    marginBottom: theme.spacing(4),
    maxWidth: '900px',
    [theme.mediaRule('MEDIUM_UP')]: {
      fontSize: '24px',
      lineHeight: 1.33,
      marginBottom: theme.spacing(6),
    },
  },
  imageBoxWrapper: {
    display: 'flex',
    flexDirection: 'column',
    gap: theme.spacing(6),
    marginBottom: theme.spacing(4),
    [theme.mediaRule('MEDIUM_UP')]: {
      gap: theme.spacing(7),
      flexDirection: 'row',
      justifyContent: 'center',
      marginBottom: theme.spacing(6),
      '& > div': {
        flexBasis: '33%',
      },
    },
  },
}));

const InspireAndAdvance = (props) => {
  const {
    locationId = '',
  } = props;
  const classes = useStyles();
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.LARGE_DOWN);
  const { handleOpenCloseModal } = useStoreContext();

  return (
    <section id="inspireAndAdvanceSection" className={classes.inspireAndAdvance}>
      <div id="inspireWrapper" className={classes.inspireWrapper}>
        <div className={classes.inner}>
          <h1 id="inspireTitle">Inspire Early Learning and Achieve Your Goals</h1>
          <div id="inspireMessage" className={classes.message}>
            KidPass Tutors By Begin is a top-rated program that specializes in tutoring for ages 3 to 13+.
            We offer personalized 1:1 instruction with experienced educators.
            Our expert tutors will prepare your child for success in school, develop core skills, and foster a love of learning.
            Get started today with a free consultation to determine your child&apos;s needs and get matched with the perfect tutor.
          </div>
          <Button id={`buttonInspire${locationId}`} variant="contained" fullWidth={isMobile} onClick={handleOpenCloseModal}>Get Started</Button>
        </div>
      </div>
      <div id="advanceWrapper" className={classes.advanceWrapper}>
        <div className={classes.inner}>
          <h1 id="advanceTitle">Advance Your Child&apos;s Learning Journey</h1>
          <div id="advanceImageBoxWrapper" className={classes.imageBoxWrapper}>
            <ImageBox
              id="advancePersonalizedInstructionImageBox"
              image={personalizedInstructionImage}
              title="Personalized Instruction"
              message="One-to-one sessions with an experienced tutor and customized learning plan"
            />
            <ImageBox
              id="advanceConvenientSchedulingImageBox"
              image={convenientSchedulingImage}
              title="Convenient Scheduling"
              message="Flexible scheduling and seamless communication for parents and tutors"
            />
            <ImageBox
              id="advanceProvenResultsImageBox"
              image={provenResultsImage}
              title="Proven Results"
              message="Achieve your child's learning goals and foster a lifelong love of learning"
            />
          </div>
          <Button id={`buttonAdvance${locationId}`} variant="contained" fullWidth={isMobile} onClick={handleOpenCloseModal}>Get Started</Button>
        </div>
      </div>
    </section>
  );
};

InspireAndAdvance.propTypes = {
  locationId: PropTypes.string,
};

export default InspireAndAdvance;
