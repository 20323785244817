import PropTypes from 'prop-types';
import React from 'react';

import { CustomRadio, ErrorMessage } from '../../../form';
import Step from './BaseStep';
import Stack from '../../../layout/Stack';

const ChildAge = (props) => {
  return (
    <Step>
      <h4 id="questionAge">What is your child&apos;s age?</h4>
      <ErrorMessage
        id="questionAgeError"
        align="center"
        errorMessages={props.errorMessages}
      />
      <Stack spacing={2} direction="column" onChange={props.handleOnChange}>
        <CustomRadio id="answerAge0-2" value="0-2" label="0-2" name="childAge" />
        <CustomRadio id="answerAge3-4" value="3-4" label="3-4" name="childAge" />
        <CustomRadio id="answerAge5-6" value="5-6" label="5-6" name="childAge" />
        <CustomRadio id="answerAge7-9" value="7-9" label="7-9" name="childAge" />
        <CustomRadio id="answerAge10-13" value="10-13" label="10-13" name="childAge" />
        <CustomRadio id="answerAge14+" value="14+" label="14+" name="childAge" />
      </Stack>
    </Step>
  );
};

ChildAge.propTypes = {
  handleOnChange: PropTypes.func,
  errorMessages: PropTypes.array,
};

export default ChildAge;
