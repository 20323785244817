import PropTypes from 'prop-types';
import React from 'react';
import { createUseStyles } from 'react-jss';

const ratingStarsImage = require('./assets/rating-stars.svg');

export const maxWidth = 390;

const useStyles = createUseStyles(theme => ({
  testimonialBox: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    padding: theme.spacing(5),
    textAlign: 'center',
    borderRadius: theme.radius(2),
    background: 'white',
    border: `solid 1px ${theme.palette.gray.border}`,
    maxWidth: `${maxWidth}px`,
    minHeight: '333.5px',
    [theme.mediaRule('MEDIUM_UP')]: {
      padding: theme.spacing(7),
      minHeight: '390px',
    },
    '& > h1': {
      fontSize: '28px',
      lineHeight: 1.29,
      fontWeight: theme.fontWeight.medium,
      marginBottom: theme.spacing(2),
      [theme.mediaRule('MEDIUM_UP')]: {
        fontSize: '32px',
        lineHeight: 1.25,
      },
    },
  },
  rating: {
    width: '112px',
    height: '20px',
    marginBottom: theme.spacing(3),
    [theme.mediaRule('MEDIUM_UP')]: {
      width: '134px',
      height: '24px',
    },
  },
  image: {
    height: '145px',
    width: '145px',
    backgroundRepeat: 'no-repeat',
    backgroundSize: 'contain',
    backgroundPosition: 'center center',
    marginBottom: theme.spacing(4),
    [theme.mediaRule('MEDIUM_UP')]: {
      height: '160px',
      width: '160px',
    },
  },
  message: {
    fontSize: '18px',
    lineHeight: 1.33,
    marginBottom: theme.spacing(3),
    [theme.mediaRule('MEDIUM_UP')]: {
      fontSize: '20px',
      lineHeight: 1.4,
      marginBottom: theme.spacing(4),
    },
  },
  name: {
    fontWeight: theme.fontWeight.medium,
    fontSize: '16px',
    lineHeight: 1.38,
    marginBottom: '2px',
    [theme.mediaRule('MEDIUM_UP')]: {
      fontSize: '18px',
      lineHeight: 1.33,
      marginBottom: '4px',
    },
  },
  bio: {
    fontSize: '16px',
    lineHeight: 1.38,
  },
}));

const TestimonialBox = (props) => {
  const {
    id,
    message,
    image,
    name,
    bio,
  } = props;
  const classes = useStyles();

  return (
    <div id={id} className={classes.testimonialBox}>
      <div
        id={id && `${id}Image`}
        className={classes.image}
        style={{ backgroundImage: `url(${image})` }}
        role="img"
        aria-label={`Image of ${name}`}
      />
      <img id={id && `${id}Rating`} src={ratingStarsImage} className={classes.rating} alt="5 star rating" />
      <div id={id && `${id}Message`} className={classes.message}>{ message }</div>
      <div id={id && `${id}Name`} className={classes.name}>{name}</div>
      <div id={id && `${id}Bio`} className={classes.bio}>{bio}</div>
    </div>
  );
};

TestimonialBox.propTypes = {
  id: PropTypes.string,
  message: PropTypes.oneOf([PropTypes.string, PropTypes.node]).isRequired,
  image: PropTypes.string.isRequired,
  name: PropTypes.oneOf([PropTypes.string, PropTypes.node]).isRequired,
  bio: PropTypes.oneOf([PropTypes.string, PropTypes.node]).isRequired,
};

export default TestimonialBox;
