import React, { useState, useCallback, useEffect } from 'react';
import { createUseStyles, useTheme } from 'react-jss';
import { useMediaQuery } from '@react-hook/media-query';
import cx from 'classnames';

import tutorItems from '../../../components/tutors/tutorItems';
import TutorBox from '../../../components/tutors/TutorBox';
import Button from '../../../components/Button';
import { useStoreContext } from '../../../StoreContext';

const moreArrowIcon = require('../assets/more-arrow.svg');
const lessArrowIcon = require('../assets/less-arrow.svg');

const useStyles = createUseStyles(theme => ({
  testimonials: {
    width: '100%',
    background: theme.palette.yellow.lightest,
  },
  inner: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    textAlign: 'center',
    margin: '0 auto',
    padding: theme.spacing('80px', 4),
    maxWidth: `${theme.sectionMaxWidth}px`,
    [theme.mediaRule('MEDIUM_UP')]: {
      padding: theme.spacing('120px', 4),
      '& button': {
        width: '250px',
      },
    },
    '& > h1': {
      fontSize: '28px',
      lineHeight: 1.29,
      fontWeight: theme.fontWeight.medium,
      marginBottom: theme.spacing(2),
      [theme.mediaRule('MEDIUM_UP')]: {
        fontSize: '32px',
        lineHeight: 1.25,
        marginBottom: theme.spacing(3),
      },
    },
    [theme.mediaRule('LARGE_UP')]: {
      paddingLeft: '50px',
      paddingRight: '50px',
    },
  },
  message: {
    fontSize: '20px',
    lineHeight: 1.4,
    marginBottom: theme.spacing(3),
    maxWidth: '900px',
    [theme.mediaRule('MEDIUM_UP')]: {
      fontSize: '24px',
      lineHeight: 1.33,
      marginBottom: theme.spacing(6),
    },
  },
  tutorsWrapper: {
    display: 'flex',
    flexDirection: 'column',
    rowGap: theme.spacing(8),
    alignItems: 'center',
    margin: '50px auto 0 auto',
    [theme.mediaRule('LARGE_UP')]: {
      flexDirection: 'row',
      alignItems: 'flex-start',
      flexWrap: 'wrap',
      justifyContent: 'space-between',
      columnGap: '50px',
      rowGap: '80px',
    },
    '@media only screen and (max-width: 1200px)': {
      '& > [class^="tutorBox"]': {
        flex: '1 1 259px', // flex-basis is a magic number... (992px breakpoint - 100px padding & 100px gap - scrollbar) / 3 items per row
      },
    },
  },
  showMore: {
    textAlign: 'center',
    fontSize: '16px',
    lineHeight: 1.38,
    marginTop: theme.spacing(8),
    '& > span': {
      cursor: 'pointer',
      color: theme.palette.primary.main,
      '&:hover, &:focus-visible': {
        color: theme.palette.primary.dark,
      },
    },
  },
  showMoreArrow: {
    display: 'inline-block',
    width: '12px',
    height: '8px',
    marginLeft: '8px',
    verticalAlign: 'middle',
    maskSize: 'contain',
    maskRepeat: 'no-repeat',
    backgroundColor: theme.palette.primary.main,
    'span:hover > &, span:focus-visible > &': {
      backgroundColor: theme.palette.primary.dark,
    },
    '&.more': {
      maskImage: `url(${moreArrowIcon})`,
    },
    '&.less': {
      maskImage: `url(${lessArrowIcon})`,
    },
  },
}));

const Testimonials = () => {
  const classes = useStyles();
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.MEDIUM_DOWN);
  const showTutorsToggle = useMediaQuery(theme.breakpoints.LARGE_DOWN);
  const [showMore, setShowMore] = useState(false);
  const { handleOpenCloseModal } = useStoreContext();

  const toggleShowMore = useCallback(() => {
    setShowMore((prevShowMore) => !prevShowMore);
  }, []);

  useEffect(() => {
    setShowMore(false);
  }, [showTutorsToggle]);

  return (
    <section className={classes.testimonials}>
      <div className={classes.inner}>
        <h1>See Top Tutors on Our Platform</h1>
        <div className={classes.message}>
          Using our custom-matching process, KidPass Tutors finds the perfect tutor for your child’s needs and schedule.
          We choose only the most qualified and passionate educators through rigorous certifications screening, background checks, and interviews.
          Plus, you still get final approval on the tutor match!
        </div>
        <Button id="buttonTutorsTemplate" variant="contained" fullWidth={isMobile} onClick={handleOpenCloseModal}>Get Started</Button>

        <div className={classes.tutorsWrapper}>
          { tutorItems.slice(0, showTutorsToggle && !showMore ? 3 : tutorItems.length).map((item) => (
            <TutorBox key={item.name} {...item} />
          ))}
        </div>
        { showTutorsToggle && (
          <div className={classes.showMore}>
            <span
              role="button"
              tabIndex="0"
              onKeyPress={e => e.key === 'Enter' && e.target.click()}
              onClick={toggleShowMore}
            >
              {`View ${showMore ? 'fewer' : 'more'} tutors`}
              <div className={cx(classes.showMoreArrow, showMore ? 'less' : 'more')} />
            </span>
          </div>
        )}
      </div>
    </section>
  );
};

export default Testimonials;
